/* eslint-disable object-curly-newline */
import { useTranslation } from 'react-i18next';
import {
  FooterColumnContentText,
  FooterLinks,
  ExternalFooterLinks,
} from './index.style';
import { FooterNavChildrens } from '../../TsTypes';

const FooterButton = ({
  title,
  url,
  external,
  icon,
  index,
}: FooterNavChildrens) => {
  const [t] = useTranslation();
  const Icon = icon;
  if (url) {
    return (
      <>
        {(external && (
          <ExternalFooterLinks href={url} target="_blank" index={index}>
            <FooterColumnContentText index={index}>
              <span>
                {Icon}
                {title && t(title)}
              </span>
            </FooterColumnContentText>
          </ExternalFooterLinks>
        )) || (
          <FooterLinks to={url} index={index}>
            <FooterColumnContentText index={index}>
              <span>
                {Icon} {title && t(title)}
              </span>
            </FooterColumnContentText>
          </FooterLinks>
        )}
      </>
    );
  }
  return (
    <FooterColumnContentText index={index}>
      <span>
        {Icon} {title && t(title)}
      </span>
    </FooterColumnContentText>
  );
};

export default FooterButton;

import { useTranslation } from 'react-i18next';
import { MdEvent } from 'react-icons/md';
import GenericPage from '../../Generic';
import {
  CommonParagraph,
  CommonParagraphContainer,
  EventDateContainer,
} from '../../../common/style/index.style';

function ActivitiesDetails(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <img
          src="https://dflfmau07swcr.cloudfront.net/9-CgNC2wwL4My83i5M5wuKTcDgKxTLFN.jpg"
          alt="Love The Nature 2016"
          style={{ width: '100%', height: 'auto', borderRadius: '1em' }}
        />
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <CommonParagraph>
          <EventDateContainer>
            <MdEvent style={{ fontSize: '2em' }} />
            <span>6 May 2019</span>
          </EventDateContainer>
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <CommonParagraph>
          Seventh annual celebration of Love The Nature has successfully
          organized at AITM building Khumaltar on the occasion of 14th February.
          With its regular segment of five campaign, we have one more
          significant segment was adjust in which Nobel laureate Dr. Rajendra
          Pachauri deliver his Skype speech to explore his solidarity in our
          campaign.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title="Love The Nature 2016" content={content} />;
}

export default ActivitiesDetails;

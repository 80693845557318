import styled, { keyframes } from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';
import { Container, maxWidth } from '../../common/style/index.style';

const SlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0.4em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
`;

const SlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-0.4em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
`;

export const PageContainer = styled(Container)`
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: ${maxWidth};
  padding-top: 3em;
  padding-bottom: 2em;
  padding-left: 0em;
  padding-right: 0em;
  @media ${device.tablet} {
    padding-top: 5em;
    padding-bottom: 3em;
  }
`;

export const PageTitle = styled.h1`
  padding-left: 0.5em;
  padding-right: 0.5em;
  animation: ${SlideUp} 0.4s forwards;
`;

export const PageContent = styled.section<{ disableContentAnimation?: string }>`
  opacity: ${(prop) => {
    if (prop.disableContentAnimation && prop.disableContentAnimation === true.toString()) {
      return 1;
    }
    return 0;
  }};
  width: 100%;
  animation: ${(prop) => {
    if (prop.disableContentAnimation && prop.disableContentAnimation === true.toString()) {
      return 'none';
    }
    return SlideDown;
  }}
    0.4s forwards;
`;

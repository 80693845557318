import { useEffect } from 'react';
import { PageContainer, PageTitle, PageContent } from './index.style';

function GenericPage({
  title,
  content,
  disableContentAnimation,
}: {
  title: string;
  content?: JSX.Element;
  disableContentAnimation?: string;
}): JSX.Element {
  useEffect(() => {
    document.title = title;
  }, []);
  return (
    <PageContainer className={'PageContainer'}>
      <PageTitle>{title}</PageTitle>
      <PageContent disableContentAnimation={disableContentAnimation}>
        {content}
      </PageContent>
    </PageContainer>
  );
}

export default GenericPage;

import { v4 as uuidv4 } from 'uuid';
import { FormField, InputFieldContainer } from '../../common/style/index.style';

const InputField = ({
  title,
  type,
  placeHolder,
  value,
  autoFocus,
}: {
  title?: string;
  type?: string;
  placeHolder?: string;
  value?: string;
  autoFocus?: boolean;
}) => {
  const id = uuidv4();
  return (
    <InputFieldContainer>
      <label style={{ fontWeight: 700 }} htmlFor={id}>
        {title}
      </label>
      <FormField
        autoFocus={autoFocus}
        type={type}
        id={id}
        name={title?.replace(' ', '_')}
        placeholder={placeHolder}
        value={value}
      />
    </InputFieldContainer>
  );
};

export default InputField;

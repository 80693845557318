/* eslint-disable operator-linebreak */
export const NAV_ABOUT = 'NAV.NAV_ABOUT';
export const NAV_STP = 'NAV.NAV_STP';
export const NAV_MV = 'NAV.NAV_MV';
export const NAV_OG = 'NAV.NAV_OG';
export const NAV_AT = 'NAV.NAV_AT';
export const NAV_MT = 'NAV.NAV_MT';
export const NAV_IC = 'NAV.NAV_IC';
export const NAV_CAMPAIGN = 'NAV.NAV_CAMPAIGN';
export const NAV_SG = 'NAV.NAV_SG';
export const NAV_LTN = 'NAV.NAV_LTN';
export const NAV_ACTIVITIES = 'NAV.NAV_ACTIVITIES';
export const NAV_VOLUNTEERS = 'NAV.NAV_VOLUNTEERS';
export const NAV_CONTACT = 'NAV.NAV_CONTACT';
export const NAV_VOLUNTEER = 'NAV.NAV_VOLUNTEER';
export const NAV_GALLERY = 'NAV.NAV_GALLERY';
export const CONTACT_LOCATION = 'CONTACT.LOCATION';
export const CONTACT_PO_BOX = 'CONTACT.PO_BOX';
export const CONTACT_COUNTRY_REGION = 'CONTACT.COUNTRY_REGION';
export const CONTACT_PHONE = 'CONTACT.PHONE';
export const CONTACT_EMAIL = 'CONTACT.EMAIL';
export const FOOTER_QUICK_LINKS = 'FOOTER.QUICK_LINKS';
export const FOOTER_NEWS_AND_EVENTS = 'FOOTER.NEWS_AND_EVENTS';
export const FOOTER_COMPANY_INFO = 'FOOTER.COMPANY_INFO';
export const FOOTER_SOCIAL_MEDIA_FOLLOW = 'FOOTER.SOCIAL_MEDIA';
export const FOOTER_COPYRIGHT = 'FOOTER.COPYRIGHT';
export const FOOTER_POWER = 'FOOTER.POWER';
export const HEADER_STP = 'NAV.NAV_STP';
export const HEADER_MV = 'NAV.NAV_MV';
export const HEADER_OG = 'NAV.NAV_OG';
export const HEADER_AT = 'NAV.NAV_AT';
export const HEADER_MT = 'NAV.NAV_MT';
export const HEADER_IC = 'NAV.NAV_IC';
export const HEADER_SG = 'NAV.NAV_SG';
export const HEADER_PC = 'NAV.NAV_PC';
export const HEADER_CU = 'NAV.NAV_CU';
export const HEADER_LTN = 'NAV.NAV_LTN';
export const HEADER_ACTIVITIES = 'NAV.NAV_ACTIVITIES';
export const HEADER_VOLUNTEERS = 'NAV.NAV_VOLUNTEERS';
export const HEADER_CONTACT = 'PAGES.CONTACT';
export const HEADER_VOLUNTEER = 'NAV.NAV_VOLUNTEER';
export const HEADERR_NEWS_AND_EVENTS = 'FOOTER.NEWS_AND_EVENTS';
export const HEADER_BOSTON = 'NEWS_AND_EVENTS.BOSTON';
export const HEADER_IUCN_WORLD_CONSERVATION =
  'NEWS_AND_EVENTS.IUCN_WORLD_CONSERVATION';
export const HEADER_GALLERY = 'PAGES.PAGES';

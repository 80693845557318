import styled from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';

export const NavAndEventContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media ${device.mobileLarge} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

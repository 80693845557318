import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Container } from '../../common/style/index.style';
import { device } from '../../MediaQuery/MediaQuery';
import { greyBorder, navSubmenuColor, navShadow } from '../../common/colors';

export const ActivitiesContainer = styled(Container)`
  flex-direction: column;
  width: calc(100% - 1.6em);
  align-items: flex-start;
  padding: 0.5em;
  border: 1px solid ${greyBorder};
  transition: all 0.4s;
  @media ${device.mobileLarge} {
    width: calc(50% - 1.6em);
  }
  @media ${device.laptop} {
    width: calc(33% - 1.6em);
  }
  &:hover {
    box-shadow: ${navShadow};
  }
`;

export const DescriptionContainer = styled(Container)`
  padding: 0.5em 0em;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media ${device.mobileSmall} {
    flex-direction: row;
  }
`;

export const DescriptionContent = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15em;
  @media ${device.mobileLarge} {
    width: 10em;
  }
`;

export const ReadMoreBth = styled(NavLink)`
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: ${navSubmenuColor};
  font-weight: bold;
`;

export const DescriptionContentTitle = styled.h4`
  padding-top: 0.5em;
`;

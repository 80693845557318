import styled from 'styled-components';
import { Container } from '../../common/style/index.style';

const PageContainer = styled(Container)`
  flex-grow: 1;
  jusrify-content: center;
  align-items: center;
  font-size: 3rem;
  flex-direction: column;
`;

function NotFound(): JSX.Element {
  return (
    <PageContainer>
      <span>404</span>
      <span>Page Not Found</span>
    </PageContainer>
  );
}

export default NotFound;

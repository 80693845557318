import {
  ActivitiesContainer,
  DescriptionContainer,
  DescriptionContent,
  ReadMoreBth,
  DescriptionContentTitle,
} from './index.style';

const ActivieiesCard = ({
  image,
  title,
  description,
  url = '/',
}: {
  image?: string;
  title?: string;
  description?: string;
  url?: string;
}) => (
  <ActivitiesContainer>
    {image && <img src={image} alt={title} style={{ width: '100%' }} />}
    <DescriptionContentTitle>{title}</DescriptionContentTitle>
    <DescriptionContainer>
      <DescriptionContent>{description}</DescriptionContent>
      <ReadMoreBth to={url}>Read More</ReadMoreBth>
    </DescriptionContainer>
  </ActivitiesContainer>
);

export default ActivieiesCard;

import styled from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';

export const TeamBodyContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: stretch;
  .IndividualCardContainer:last-child {
    margin-right: auto;
  }
  @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

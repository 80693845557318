import moment from 'moment';
import {
  LinkCardContainer,
  EventDate,
  EventLink,
  EventDetailsButton,
} from './index.style';

const NewsCard = ({
  title,
  dated,
  url,
  image,
}: {
  title?: string;
  dated?: string;
  url?: string;
  image?: string;
}) => {
  const eventDate = moment(dated, 'YYYY-MM-DD').format('MMMM Do YYYY');

  return (
    <LinkCardContainer className={'LinkCardContainer'}>
      <EventLink to={url || '#'}>
        <section
          style={{
            display: 'block',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexGrow: 1,
            position: 'relative',
            flexDirection: 'column',
            color: 'black',
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              alignItems: 'flex-start',
            }}
          >
            <EventDate className="eventDate">{eventDate}</EventDate>
            <EventDetailsButton>Details</EventDetailsButton>
          </div>
        </section>
        <div className={'eventTitle'} style={{ padding: '0.1em 0em' }}>
          {title}
        </div>
      </EventLink>
    </LinkCardContainer>
  );
};

export default NewsCard;

import React, { useEffect } from 'react';
import { NavigationProp } from '../TsTypes';
import {
  NavigationContainer,
  ArticleContainer,
  FooterContainer,
  CopyrightContainer,
  SocialMediaContainer,
  FooterNavMenuContainer,
} from './index.style';
import Navigation from '../Component/navigation';
import { Overlay, maxWidth } from '../common/style/index.style';
import { navPrimary } from '../common/aria';
import Footer from '../Component/Footer';
import SocialMedia from '../Component/SocialMedia';
import { useAppDispatch } from '../ReduxStore/hook';
import { getSiteInformation } from '../Service/home';

const Layout = ({ children }: NavigationProp): JSX.Element => {
  // const loginError = useAppSelector((state) => state.error);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getSiteInfo = async () => {
      await dispatch(
        getSiteInformation(),
      ).catch((error: any) => console.log('', error));
    };
    getSiteInfo();
  }, []);

  return (<>
    <NavigationContainer aria-label={navPrimary}>
      <Navigation />
    </NavigationContainer>
    <ArticleContainer id={'article'} aria-labelledby="heading-h1-id">
      { children }
    </ArticleContainer>
    <FooterContainer aria-labelledby="heading-hother-id">
      <FooterNavMenuContainer>
        <Overlay
          style={{
            maxWidth: `${maxWidth}`,
            background: 'transparent',
          }}
        >
          <Footer />
        </Overlay>
      </FooterNavMenuContainer>
      <SocialMediaContainer>
        <Overlay
          style={{
            maxWidth: `${maxWidth}`,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: 'transparent',
          }}
        >
          <SocialMedia />
        </Overlay>
      </SocialMediaContainer>
      <CopyrightContainer>
        <Overlay
          style={{
            maxWidth: `${maxWidth}`,
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '0.8rem',
            background: 'transparent',
          }}
        >
          <span>&copy; Save The Planet 2022</span>
          <span>Powered by the Trident</span>
        </Overlay>
      </CopyrightContainer>
    </FooterContainer>
  </>);
};
export default Layout;

import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_MV } from '../../common/translation';
import {
  CommonParagraph,
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function MissionAndVision(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>MISSION</ParagraphHeader>
        <CommonParagraph>
          Save the Planet Global Organization is dedicated to safeguarding
          the health and well-being of our beloved planet Earth, its inhabitants,
          and the five essential elements of life. We are committed to preserving
          the delicate balance of nature, protecting the flora and fauna that enrich
          our world, and fostering a sustainable coexistence between humanity and the environment.

        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>VISION</ParagraphHeader>
        <CommonParagraph>
          Our vision is a harmonious and resilient world where the health
          and well-being of the planet Earth, its diverse communities, and
          the five vital elements are preserved for current and future generations.
          We strive to create a global society that recognizes the interconnectedness
          of all life and respects the intrinsic value of our natural environment.
          Through our dedicated efforts, we aim to ensure a thriving Earth where
          ecosystems flourish, wildlife thrives, and people live in harmony with
          the planet they call home. Together, we envision a brighter, more sustainable
          future, where the legacy we leave is one of stewardship, balance, and lasting prosperity.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_MV)} content={content} />;
}

export default MissionAndVision;

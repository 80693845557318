import axios from 'axios';
import { SERIVICE_HOST, SERIVICE_PORT } from '../config';

export const instance = axios.create({
  baseURL: `${SERIVICE_HOST}:${SERIVICE_PORT}`,
  timeout: 3000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

export const response = {
  success: false,
  data: {},
  message: 'Oops! Something went wrong.',
};

import styled from 'styled-components';
import { Container } from '../../common/style/index.style';
import { device } from '../../MediaQuery/MediaQuery';

export const GalleryCardContainer = styled(Container)`
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5em;
  section:last-child {
    margin-right: auto;
  }
  @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

import styled from 'styled-components';
import { Container } from '../../common/style/index.style';
import { device } from '../../MediaQuery/MediaQuery';
import { primaryColor, navSubmenuColor } from '../../common/colors';

export const VolunteersContainer = styled(Container)`
  flex-grow: 1;
  padding: 1em;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    background-image: url('/Images/globe.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: opacity(0.4) blur(0.3em);
    @media ${device.mobileLarge} {
      filter: opacity(0.4) blur(0.3em);
    }
    @media ${device.tablet} {
      filter: opacity(0.4) blur(0.5em);
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    background-image: url('/Images/tree.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: opacity(0.7) blur(0.3em);
    @media ${device.mobileLarge} {
      filter: opacity(0.7) blur(0.3em);
    }
    @media ${device.tablet} {
      filter: opacity(0.7) blur(0.3em);
    }
  }
`;

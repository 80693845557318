import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_LTN } from '../../common/translation';
import {
  CommonParagraph,
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function LoveTheNature(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>Introduction</ParagraphHeader>
        <CommonParagraph>
          In our rapidly changing world, it has become a global imperative to
          educate and inspire the general public about the environmental challenges
          that affect us all. The need to kindle environmental consciousness
          transcends borders and becomes an opportunity to unite people from all
          corners of the world. What better place to embark on this mission than
          in our bustling urban centers, where the majority of our global population
          resides? The impact of industrial emissions and vehicular pollution on human
          health is a universal concern, while the relentless march of concrete jungle
          continues to obscure the natural beauty that once defined our cities.
        </CommonParagraph>
        <CommonParagraph>
          In communities worldwide, educated individuals wield the power to illuminate
          the path of knowledge and nurture positive thinking, thereby dispelling the
          prevailing darkness. Regrettably, many cities worldwide still cast a shadow
          over their inhabitants with their unattractive urban landscapes. Throughout
          various stages of life, we have all accumulated knowledge and skills that empower
          us to make more informed decisions, both personally and professionally. However,
          our generation confronts a spectrum of challenges, ranging from political strife
          to environmental crises, all of which subject us to pollution on multiple fronts.
        </CommonParagraph>
        <CommonParagraph>
          Therefore, it is not just a local need but a global imperative to disseminate
          environmental knowledge and mobilize practical actions among people worldwide.
          Let us collectively reignite our love for nature and make it a shared commitment
          to protect our planet.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Executive Summary</ParagraphHeader>
        <CommonParagraph>
          On 14th February, a day traditionally set aside for celebrating love between
          two hearts, we, as global environmental activists, celebrate the love between
          the human heart and the Earth itself – "Love The Nature."
        </CommonParagraph>
        <CommonParagraph>
          We, under the banner of a united global effort, are calling for the recognition
          of 14th February as a universal day for nature lovers, transcending borders and
          cultures. This initiative encourages governments and organizations worldwide to
          come together in the name of a common love – love for our planet.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Promotion</ParagraphHeader>
        <CommonParagraph>
          We are promoting "Love The Nature" through a series of five major activities,
          designed to galvanize people globally
        </CommonParagraph>
        <OrderedCommonList>
          <CommonListItem>
            <em>Speech Campaign</em>: Harnessing diverse ideas and voices.
          </CommonListItem>
          <CommonListItem>
            <em>Art (drawing/playing) Campaign</em>:
            Igniting creativity and innovation across cultures.
          </CommonListItem>
          <CommonListItem>
            <em>Signature Campaign</em>:
            Gathering commitments from individuals and communities worldwide.
          </CommonListItem>
          <CommonListItem>
            <em>Clean & Green Campaign</em>:
            Nurturing a healthier planet and cleaner environments across the globe.
          </CommonListItem>
          <CommonListItem>
            <em>Media Campaign</em>:
            Elevating public awareness and fostering global replication of our efforts.
            Let's take action together for the future of our planet!
          </CommonListItem>
        </OrderedCommonList>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_LTN)} content={content} />;
}

export default LoveTheNature;

import styled from 'styled-components';
import { Container } from '../../common/style/index.style';
import { device } from '../../MediaQuery/MediaQuery';
import { primaryColor, navSubmenuColor } from '../../common/colors';

export const ContactContainer = styled(Container)`
  flex-grow: 1;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const MainContactContainer = styled(Container)`
  flex-grow: 1;
  padding: 1em;
  flex-direction: column;
`;

export const ContactInformationContainer = styled.section`
  flex-grow: 1;
  padding: 1em 0em;
  flex-direction: column;
  display: flex;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const ContactInformationContentContainer = styled.section`
  flex-grow: 1;
  padding: 0.1em 0em;
  flex-direction: column;
  width: 100%;
  @media ${device.tablet} {
    width: 32%;
    padding: 0.1em 0.1em;
  }
`;

export const ContactInformationContent = styled.a`
  flex-grow: 1;
  flex-direction: row;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  height: 100%;
  border-radius: 1em;
  background-color: ${primaryColor};
  transition: all 0.4s;
  &:hover {
    background-color: black;
    color: white;
  }
`;

export const ContactInformationDetail = styled.div`
  padding: 0.2em 0em;
`;

import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CommonParagraph, CommonParagraphContainer, Overlay, maxWidth,
} from '../../common/style/index.style';
import {
  HomeBodyContainer,
  HomeBannerContainer,
  HomeBanner,
  NewsAndEventMore,
  HomeBannerItemContainer,
  HeroHome,
  HeroHomeContainer,
  HeroHomeHeader,
  HeroHomeButton,
} from './index.style';
import Carousel from '../../Component/Carousel';
import { NavAndEventContainer } from '../NewsAndEvents/index.style';
import NewsEentCard from '../../Component/NewsAndEvents';
import {
  NEWS_AND_EVENTS,
  SCHOOL_GREEN,
  LOVE_THE_NATURE,
  NEWS_AND_EVENTS_BOSTON,
  NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
  VOLUNTEER,
} from '../../common/routes';

const eventsData = [
  {
    title: 'IUCN World Conservation Congress 2020',
    dated: '2019/05/06',
    url: NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
    image:
      'https://dflfmau07swcr.cloudfront.net/-xGvEll65-kmQdfg8cpLpS3nPV0L0_UL.jpg',
  },
  {
    title: '12th Annual Boston Green Fest',
    dated: '2019/05/06',
    url: NEWS_AND_EVENTS_BOSTON,
    image:
      'https://dflfmau07swcr.cloudfront.net/8I1w3PbgcBxquVzQVRpwyAGmxVKQ5MJ0.jpg',
  },
];

const Home = (): JSX.Element => {
  const [isLiading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = 'Home | Save The Planet';
  }, []);

  const MappedNewsCard = eventsData.map((elem) => (
    <NewsEentCard
      title={elem.title}
      dated={elem.dated}
      url={elem.url}
      image={elem.image}
      key={uuidv4()}
    />
  ));

  const handleLoadingComplete = () => {
    setIsLoading(!isLiading);
  };

  const Images = [
    {
      url: 'https://dflfmau07swcr.cloudfront.net/QJszWqFpQsVw0w-uPZ8vDr7vvmpfQEIt.jpg',
      alternate: 'Images',
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/0GYciPHsNHHb_fa_wxd_fS-zwY02QeqB.jpg',
      alternate: 'Images',
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/6qxQVy3VmD9yfvnFNQuqmUIY4rGwq_Eh.jpg',
      alternate: 'Images',
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/N7BdnrrkQ7itkltWGYAXXJrSM0_LjuYV.jpg',
      alternate: 'Images',
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/q4lSgcdhxDybmDVm6LxR2--Wddd2SX05.jpg',
      alternate: 'Images',
    },
  ];

  return (
    <Overlay
      style={{
        padding: 0,
        flexDirection: 'column',
        fontWeight: '100',
        fontSize: '1rem',
      }}
    >
      {/* <Carousel images={Images} />
        { isLiading && <div>LOADING........</div> }
      */}
      <HeroHome >
        <HeroHomeContainer>
          <HeroHomeHeader>
            <div></div>
            <div>Act today, save tomorrow</div>
          </HeroHomeHeader>
          <div>
            Preserve the planet for a brighter future.
          </div>
          {/* <HeroHomeButton href={ VOLUNTEER }>
            Volunteer
          </HeroHomeButton> */}
        </HeroHomeContainer>
      </HeroHome>
      <HomeBodyContainer>
        <Overlay
          style={{
            maxWidth: `${maxWidth}`,
            flexDirection: 'column',
          }}
        >
          <h2>Save The Planet</h2>
          <CommonParagraphContainer>
            <CommonParagraph>
          Save the Planet is a global nonprofit organization registered as a 501(c)(3)
          in the United States. This means that donations made to us are tax-deductible.
          Our mission is to address pressing environmental issues and protect our planet.
            </CommonParagraph>
            <CommonParagraph>
          Our world is facing serious environmental challenges, and we believe it's our
          responsibility to safeguard our shared home. We invite you to join the Planet
          Club and be part of our efforts. Your support helps us make a difference.
            </CommonParagraph>
          </CommonParagraphContainer>
          <h2>Our Campaigns</h2>
          <HomeBannerContainer>
            <HomeBannerItemContainer>
              <HomeBanner to={LOVE_THE_NATURE}>
                <img
                  src="https://dflfmau07swcr.cloudfront.net/loveNature.jpg"
                  alt="Love Nature"
                  style={{ width: '100%' }}
                  onLoad={handleLoadingComplete}
                />
              </HomeBanner>
            </HomeBannerItemContainer>
            <HomeBannerItemContainer>
              <HomeBanner to={SCHOOL_GREEN}>
                <img
                  src="https://dflfmau07swcr.cloudfront.net/greenCampaign.jpg"
                  alt="School Green"
                  style={{ width: '100%' }}
                />
              </HomeBanner>
            </HomeBannerItemContainer>
          </HomeBannerContainer>
          <section
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h2 style={{ display: 'inline-block' }}>News</h2>
            <NewsAndEventMore to={NEWS_AND_EVENTS}>More</NewsAndEventMore>
          </section>
          <HomeBannerContainer>
            <NavAndEventContainer>{MappedNewsCard}</NavAndEventContainer>
          </HomeBannerContainer>
        </Overlay>
      </HomeBodyContainer>
    </Overlay>
  );
};

export default Home;

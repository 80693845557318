import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { IoLocationSharp } from 'react-icons/io5';
import { BsFillTelephoneFill, BsMailbox2 } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import FooterLayout from './FooterLayout';
import FooterColumn from './FooterColumn';
import {
  GALLERY,
  NEWS_AND_EVENTS,
  VOLUNTEERS,
  CONTACT,
  SAVE_THE_PLANET,
  MISSION_AND_VISION,
  OUR_GOALS,
  ACTIVITIES,
} from '../../common/routes';
import {
  NAV_STP,
  NAV_MV,
  NAV_OG,
  NAV_ACTIVITIES,
  NAV_VOLUNTEERS,
  NAV_CONTACT,
  NAV_GALLERY,
  CONTACT_LOCATION,
  CONTACT_PO_BOX,
  CONTACT_PHONE,
  CONTACT_EMAIL,
  FOOTER_QUICK_LINKS,
  FOOTER_NEWS_AND_EVENTS,
  FOOTER_COMPANY_INFO,
} from '../../common/translation';

const Navigation: React.FunctionComponent = () => {
  const [t] = useTranslation();
  const footerStructure = [
    {
      title: NAV_STP,
      navChildrens: [
        {
          title: CONTACT_LOCATION,
          icon: <IoLocationSharp style={{ paddingRight: '0.5em' }} />,
          url: 'https://www.google.com/maps/place/2601+Bluewater+Ct,+Marina,+CA+93933,+USA/@36.6616105,-121.8088937,17z/data=!3m1!4b1!4m6!3m5!1s0x808dfd21a19352b3:0x8cb1d2d84278c2e5!8m2!3d36.6616105!4d-121.8088937!16s%2Fg%2F11fk7zk3q9?entry=ttu',
          external: true,
        },
        // {
        //   title: CONTACT_PO_BOX,
        //   icon: <BsMailbox2 style={{ paddingRight: '0.5em' }} />,
        // },
        {
          title: `${t(CONTACT_PHONE)}`,
          url: `tel: ${t(CONTACT_PHONE)}`,
          external: true,
          icon: <BsFillTelephoneFill style={{ paddingRight: '0.5em' }} />,
        },
        {
          title: `${t(CONTACT_EMAIL)}`,
          url: `mailto: ${t(CONTACT_EMAIL)}`,
          icon: <MdEmail style={{ paddingRight: '0.5em' }} />,
          external: true,
        },
      ],
    },
    {
      title: FOOTER_QUICK_LINKS,
      navChildrens: [
        {
          title: NAV_GALLERY,
          url: GALLERY,
        },
        {
          title: FOOTER_NEWS_AND_EVENTS,
          url: NEWS_AND_EVENTS,
        },
        {
          title: NAV_VOLUNTEERS,
          url: VOLUNTEERS,
        },
        {
          title: NAV_CONTACT,
          url: CONTACT,
        },
      ],
    },
    {
      title: FOOTER_COMPANY_INFO,
      navChildrens: [
        {
          title: NAV_STP,
          url: SAVE_THE_PLANET,
        },
        {
          title: NAV_MV,
          url: MISSION_AND_VISION,
        },
        {
          title: NAV_OG,
          url: OUR_GOALS,
        },
        {
          title: NAV_ACTIVITIES,
          url: ACTIVITIES,
        },
      ],
    },
  ];

  const footerColumn = footerStructure.map((elem, index: number) => (
    <FooterColumn
      key={uuidv4()}
      title={elem.title}
      index={index}
      navChildrens={elem.navChildrens}
    />
  ));

  return (
    <>
      <FooterLayout>{footerColumn}</FooterLayout>
    </>
  );
};

export default Navigation;

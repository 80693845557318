import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMPANYINFO } from './apiList';
import { instance } from '../ServiceProvider';
import { SiteInformation } from '../TsTypes';

export const getSiteInformation = createAsyncThunk(
  COMPANYINFO,
  (prop, { rejectWithValue, fulfillWithValue }) => instance
    .get(COMPANYINFO)
    .then((res) => fulfillWithValue(res.data && res.data.data))
    .catch((ex) => {
      if (ex.response) {
        return rejectWithValue(
          ex.response?.data?.data?.[0].messages?.[0]?.message
          || 'Oops! Something Went wrong.',
        );
      }
      return rejectWithValue('Oops! Something Went wrong.');
    }),
);

import styled from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';
import { navShadow } from '../../common/colors';

export const NameHeading = styled.h3`
  padding: 1em 0em;
`;

export const IndividualCardContainer = styled.section`
  width: 100%;
  @media ${device.mobileLarge} {
    width: 50%;
  }
  @media ${device.tablet} {
    width: 33%;
  }
`;

export const IndividualCardOverlay = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1em;
  transition: all 0.4s;
  &:hover {
    box-shadow: ${navShadow};
  }
`;

export const FigureContainer = styled.figure`
  justify-content: center;
  display: flex;
  width: 100%;
`;

export const DescriptionContainer = styled.section`
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 1em;
`;

export const IndividualEmail = styled.a`
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

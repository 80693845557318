/* eslint-disable operator-linebreak */
export const WILDCARD = '*';
export const ROOT = '/';
export const SAVE_THE_PLANET = '/save-the-planet';
export const MISSION_AND_VISION = '/mission_and_vision';
export const OUR_GOALS = '/our_goals';
export const ADVISORY_TEAM = 'advisory_team';
export const MANAGEMENT_TEAM = 'management_team';
export const INTERNATIONAL_COORDINATION = 'international_coordination';
export const TEAM = '/team';
export const ADVISORY_TEAM_LINK = `/team?type=${ADVISORY_TEAM}`;
export const MANAGEMENT_TEAM_LINK = `/team?type=${MANAGEMENT_TEAM}`;
export const INTERNATIONAL_COORDINATION_LINK = `/team?type=${INTERNATIONAL_COORDINATION}`;
export const SCHOOL_GREEN = '/school_green';
export const PLANET_CLUB = '/planet_club';
export const CHAURASI_UDHYAAN = '/chaurasi_udhyaan';
export const LOVE_THE_NATURE = '/love_the_nature';
export const ACTIVITIES = '/activities';
export const VOLUNTEERS = '/volunteers';
export const CONTACT = '/contact';
export const VOLUNTEER = '/volunteer';
export const GALLERY = '/gallery';
export const NEWS_AND_EVENTS = '/news_and_events';
export const IMAGES = '/Images';
export const NEWS_AND_EVENTS_BOSTON = '/news_and_events/details/boston';
export const NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION =
  '/news_and_events/details/iucn_world_conservation';
export const ACTIVITIES_DETAILS = '/activities/details/love-the-nature-2016';
export const GALLERY_TESTIMONIALS = '/gallery/details/testimonials';
export const GALLERY_AFFORESTATION = '/gallery/details/testimonials/afforestation';
export const GALLERY_PROGRAMME = '/gallery/details/programmes';

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './css/App.css';
import './css/normalize.css';
import Layout from './Layout';

import {
  Home,
  NotFound,
  SaveThePlanet,
  MissionAndVision,
  GoalsAndObjectives,
  Teams,
  LoveTheNature,
  SchoolGreen,
  Activities,
  Volunteers,
  Contact,
  Volunteer,
  Gallery,
  NewsAndEvents,
  NewsAndEventsBoston,
  NewsAndEventsIUCNWorldConservation,
  ActivitiesLoveTheNature,
  GalleryTestimonials,
} from './Page/index';
import {
  ROOT,
  SAVE_THE_PLANET,
  WILDCARD,
  MISSION_AND_VISION,
  OUR_GOALS,
  SCHOOL_GREEN,
  LOVE_THE_NATURE,
  ACTIVITIES,
  VOLUNTEERS,
  CONTACT,
  VOLUNTEER,
  GALLERY,
  NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_BOSTON,
  NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION,
  TEAM,
  ACTIVITIES_DETAILS,
  GALLERY_TESTIMONIALS,
  GALLERY_AFFORESTATION,
  GALLERY_PROGRAMME,
  PLANET_CLUB,
  CHAURASI_UDHYAAN,
} from './common/routes';
import { CurrentPageType } from './TsTypes';
import PlanetClub from './Page/PlanetClub';
import ChaurasiUdhyaan from './Page/ChaurasiUdhyaan';

/*

useEffect(async () => {
               // DebuggerTest();
               store.dispatch(setLoading(true));
                let Token = localStorage.getItem(TokenStorage);
                        store.dispatch(getDropDowns());
                if(Token){
                        console.log(Token);
                        setTimeout(async () => {
                                store.dispatch(await authenticateLoginToken(Token));
                        }, 300);
                }else{
                        store.dispatch(setLoading(false));
                }

        }, []);

*/

const App = ():JSX.Element => (
  <Fragment>
    <Suspense fallback={'Loading'}>
      <Fragment>
        <Router>
          <Routes>
            <Route
              path={ROOT}
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path={SAVE_THE_PLANET}
              element={
                <Layout>
                  <SaveThePlanet />
                </Layout>
              }
            />
            <Route
              path={MISSION_AND_VISION}
              element={
                <Layout>
                  <MissionAndVision />
                </Layout>
              }
            />
            <Route
              path={OUR_GOALS}
              element={
                <Layout>
                  <GoalsAndObjectives />
                </Layout>
              }
            />
            <Route
              path={TEAM}
              element={
                <Layout>
                  <Teams />
                </Layout>
              }
            />
            <Route
              path={LOVE_THE_NATURE}
              element={
                <Layout>
                  <LoveTheNature />
                </Layout>
              }
            />
            <Route
              path={SCHOOL_GREEN}
              element={
                <Layout>
                  <SchoolGreen />
                </Layout>
              }
            />
            <Route
              path={PLANET_CLUB}
              element={
                <Layout>
                  <PlanetClub />
                </Layout>
              }
            />
            <Route
              path={CHAURASI_UDHYAAN}
              element={
                <Layout>
                  <ChaurasiUdhyaan />
                </Layout>
              }
            />
            <Route
              path={ACTIVITIES}
              element={
                <Layout>
                  <Activities />
                </Layout>
              }
            />
            <Route
              path={VOLUNTEERS}
              element={
                <Layout>
                  <Volunteers />
                </Layout>
              }
            />
            <Route
              path={CONTACT}
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />
            <Route
              path={VOLUNTEER}
              element={
                <Layout>
                  <Volunteer />
                </Layout>
              }
            />
            <Route
              path={GALLERY}
              element={
                <Layout>
                  <Gallery />
                </Layout>
              }
            />
            <Route
              path={`${NEWS_AND_EVENTS}`}
              element={
                <Layout>
                  <NewsAndEvents />
                </Layout>
              }
            />
            <Route
              path={`${NEWS_AND_EVENTS_IUCN_WORLD_CONSERVATION}`}
              element={
                <Layout>
                  <NewsAndEventsIUCNWorldConservation />
                </Layout>
              }
            />
            <Route
              path={`${NEWS_AND_EVENTS_BOSTON}`}
              element={
                <Layout>
                  <NewsAndEventsBoston />
                </Layout>
              }
            />
            <Route
              path={WILDCARD}
              element={
                <Layout>
                  <NotFound />
                </Layout>
              }
            />
            <Route
              path={ACTIVITIES_DETAILS}
              element={
                <Layout>
                  <ActivitiesLoveTheNature />
                </Layout>
              }
            />
            <Route
              path={GALLERY_TESTIMONIALS}
              element={
                <Layout>
                  <GalleryTestimonials title={CurrentPageType.testemonials}/>
                </Layout>
              }
            />
            <Route
              path={GALLERY_AFFORESTATION}
              element={
                <Layout>
                  <GalleryTestimonials title={CurrentPageType.afforestation}/>
                </Layout>
              }
            />
            <Route
              path={GALLERY_PROGRAMME}
              element={
                <Layout>
                  <GalleryTestimonials title={CurrentPageType.programme}/>
                </Layout>
              }
            />
          </Routes>
        </Router>
      </Fragment>
    </Suspense>
  </Fragment>
);

export default App;

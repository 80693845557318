export const menu = 'Main menu';
export const navPrimary = 'Primary';
export const home = 'Home';
export const ariaFacebook = 'Facebook';
export const ariaGooglePlus = 'Google Plus';
export const ariaTwitter = 'Twitter';
export const ariaYoutube = 'Youtube';
export const ariaLinkedin = 'Linkedin';
export const prevButton = 'Previous Image';
export const nextButton = 'Next Image';

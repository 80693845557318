import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import GenericPage from '../Generic';
import { HEADER_GALLERY } from '../../common/translation';
import { GALLERY_AFFORESTATION, GALLERY_PROGRAMME, GALLERY_TESTIMONIALS } from '../../common/routes';
import GalleryCard from '../../Component/Gallery';
import { GalleryCardContainer } from './index.style';
import { CommonParagraphContainer } from '../../common/style/index.style';

const GalleryData = [
  {
    thumbnailURL:
      'https://dflfmau07swcr.cloudfront.net/vvq6jL3Jx8160321085456.jpg',
    title: 'Testimonials',
    url: GALLERY_TESTIMONIALS,
    displayMax: true.toString(),
  },
  {
    thumbnailURL:
      'https://dflfmau07swcr.cloudfront.net/IMG_0044.jpeg',
    title: 'Programme',
    url: GALLERY_PROGRAMME,
    displayMax: true.toString(),
  },
  {
    thumbnailURL:
      'https://dflfmau07swcr.cloudfront.net/IMG_0016.jpeg',
    title: 'Afforestation Programme',
    url: GALLERY_AFFORESTATION,
    displayMax: true.toString(),
  },
];

function Gallery(): JSX.Element {
  const [t] = useTranslation();
  const MappedGalleryData = GalleryData.map((elem) => (
    <GalleryCard
      thumbnailURL={ elem.thumbnailURL }
      title={ elem.title }
      url={ elem.url }
      key={ uuid() }
    />
  ));
  const content = (
    <>
      <CommonParagraphContainer>
        <GalleryCardContainer>{ MappedGalleryData }</GalleryCardContainer>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={ t(HEADER_GALLERY) } content={ content } />;
}

export default Gallery;

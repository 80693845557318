import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_SG } from '../../common/translation';
import {
  CommonParagraph,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function SchoolGreen(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <img
          src="https://dflfmau07swcr.cloudfront.net/VQSLAiRKb8NWSgrueC3kGjlp-ZH_dRJW.jpg"
          alt="School Green"
          style={{ width: '100%', height: 'auto', borderRadius: '1em' }}
        />
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Introduction</ParagraphHeader>
        <CommonParagraph>
          This is the need of the hour to educate our young generation on
          environmental issues faced by our nation and the world. What will be a
          better place to initiate than the schools?
        </CommonParagraph>
        <CommonParagraph>
          As the old proverb dictates, ‘Knowledge is power’ and we regard the
          schools pious place – the home of Goddess Sarawati.
        </CommonParagraph>
        <CommonParagraph>
          In our community, schools by spreading the light of knowledge
          eliminate the darkness of ignorance. We all have acquired our
          knowledge and skills that are helping us to make better decisions in
          our life in professional and personal levels during our schools,
          colleges and universities years. Our young generation is acquiring
          knowledge and skills that they will use in their turn to contribute to
          the world.
        </CommonParagraph>
        <CommonParagraph>
          Therefore, by spreading the environmental knowledge and practice among
          our young generation via LRLP Project ‘Lab for Reading & Land for
          Practice’. We can kindle love for the nature in their heart. Thus
          making them young environmental activist; encouraging them to make
          eco-friendly decisions in their day-to-day life.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Global Green Schools Initiative</ParagraphHeader>
        <CommonParagraph>
          The Global Green Schools Initiative is a transformative endeavor aimed at
          fostering sustainability in educational institutions worldwide. This
          initiative seeks to engage students in nurturing green spaces within
          their schools. Over the course of a decade, the project's ambition is
          to extend its influence to 5,000 schools across the globe, collectively
          declaring them Green Zones. By doing so, the project aspires to cultivate
          500,000 young environmental activists, with each school contributing 100
          such activists. Additionally, the initiative aims to identify and nurture
          5,000 young environmental experts, one from each school, by offering
          scholarships for their education.
        </CommonParagraph>
        <CommonParagraph>
        Beyond the confines of the school premises, the initiative will extend its
        efforts to the surrounding school catchment areas. It will identify wetlands,
        endangered species habitats (both flora and fauna), and valuable species with
        economic and environmental significance. Collaboratively with student leadership
        at each school, this initiative will compile environmental data to transform
        schools into not just centers of academic learning but also hubs for environmental
        education, thereby earning them the designation of a Green Zone.
        </CommonParagraph>
        <CommonParagraph>
        Furthermore, this project will create student green teams for grades 6 through
        12 in each school, tasking them with managing environment libraries and gardens
        established as part of the initiative. These student clubs, supervised by dedicated
        teachers and representatives of the initiative, will oversee the maintenance of
        these facilities. Through this, students will gain a comprehensive understanding
        of the importance of greenery in our lives, both in theory and practice. The project
        emphasizes the use of suitable plant species, including fruits, vegetables, medicinal
        herbs, ornamental plants, and economically valuable species. The conservation of
        wetlands is particularly highlighted, as they have the potential to become significant
        attractions for sustainable tourism. The gardens will create economic opportunities that
        address climate change and global warming issues.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Schools</ParagraphHeader>
        <CommonParagraph>
          A Green Zone project is an initiative to make the school green in the
          participation of school's students. This project cover 5000 schools
          within ten years and declared 5000 green zone throughout the nation.
          Along this we will develop of 500,000 young environment activist (100
          from one school). Similarly 5,000 young environment expert (1 from
          each school) providing scholarship in their higher study on
          environmental education in national or international
          college/university. We develop and conduct one nursery in each school
          catchment area, so that 5,000 green entrepreneur will establish with
          minimum 15,000 green employment (at least 3 persons in a nursery)
          opportunity. So, here we able to plant 2,000 plants in a school’s
          catchment area , so we can survive/grow 1,00,00,000 plants in 5,000
          schools within ten years.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraph>
        Our activities will not limit inside school’s premises but go throughout
        the school’s catchment area. We will search/observe/find wetland,
        habitat of endangered species (plants & animals), find the suitable
        economical and environmental valued species and collect all the
        environment related information with student leadership at school. So
        that, school will not an academic learning centre only it is
        environmental learning centre too, Such school is ultimately declared a
        green zone.
      </CommonParagraph>
      <CommonParagraph>
        This project will create a student's green team per school from grade 6
        to 12 and involve them by making team to run environment library and
        garden constructed by this project. Student's club under the supervision
        of coordinator teacher and organizer’s representative will care those
        lab and garden. From this project, students will learn the importance of
        greenery in our life; theoretically and practically. Here, we have to
        use suitable species, fruits, vegetable, medicinal, ornamental and other
        economical valued plants. Conserving wetland itself a high potential
        spot for tourism. Similarly, garden will give good economical activities
        addressing climate change and global warming issues.
      </CommonParagraph>
      <CommonParagraph>
        The project cost is Nrs. 5,00,000 per school equals to Nrs.
        2,50,00,00,000 . After completion of this project we can expect
        10,00,000 yearly benefit from each nursery, which is Nrs.5,00,00,00,000.
        Without other hidden income from plants farming, tourism activity
        (wetland) etc.
      </CommonParagraph>
      <CommonParagraph>
        [
        <em>
          Note: activities address to minimize global warming & climate change
          issues with developing/producing environmental activists and experts
          with good economical activities is most important factor of this
          project.
        </em>
        ]
      </CommonParagraph>
    </>
  );
  return <GenericPage title={t(HEADER_SG)} content={content} />;
}

export default SchoolGreen;

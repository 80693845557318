import React from 'react';
import detector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from './ReduxStore/store';
import reportWebVitals from './reportWebVitals';
import translationObjectEn from './Translation/TranslationEn.json';
import translationObjectNp from './Translation/TranslationNp.json';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const resources = {
  np: {
    translation: translationObjectNp,
  },
  en: {
    translation: translationObjectEn,
  },
};

i18n
  .use( detector )
  .use( initReactI18next )
  .init( {
    resources,
    supportedLngs: [ 'en', 'np' ],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  } );

const root = createRoot( document.getElementById( 'root' ) as HTMLElement );
root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { device } from '../../MediaQuery/MediaQuery';
import { Overlay, Container } from '../../common/style/index.style';
import {
  primaryColor,
  white,
  greyBorder,
  black,
  navShadow,
  navSubmenuColor,
  enhancedNavLinkStart,
  enhancedNavLinkEnd,
} from '../../common/colors';

export const HeroHome = styled.section`
  display: flex;
  justify-content: end;
  align-items: start;
  flex-direction: column;
  height: 100vh;
  background-image: url(https://dflfmau07swcr.cloudfront.net/Earth-Hour.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  @media ${device.laptop} {
  background-position: center center;
  }
`;

const heroContentFlowDown = keyframes`
  0% {
    display: flex;
    opacity: 0;
    transform: translateY(-0.6em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
`;

export const HeroHomeContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 3em;
  color: white;
  transition: all 0.3s;
  *{
    animation: ${heroContentFlowDown} 0.6s linear forwards;
  }
  @media ${device.mobileLarge} {
    padding: 5em;
  }
  @media ${device.tablet} {
    padding: 7em;
  }
`;

export const HeroHomeHeader = styled.h1`
  font-size: 2em;
  margin-bottom: 0.1em;
  @media ${device.mobileLarge} {
    font-size: 3em;
  }
  @media ${device.tablet} {
    font-size: 4em;
  }
`;

export const HeroHomeButton = styled.a`
  cursor: pointer;
  margin: 1em 0em;
  padding: 0.6em 2em;
  border-radius: 2em;
  border: 0px;
  background: red;
  font-weight:900;
  color: ${white};
  &:hover{
    background: ${white};
    color: ${black};
  }
`;

export const CarouselLegend = styled.p`
  color: red !important;
`;

export const NavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 3em;
  background-color: transparent;
  top: 0;
  z-index: 2;
  border: none;
  color: white;
`;

export const NavIconOverlay = styled.div`
  position: absolute;
  height: 100%;
  opacity: 0.09;
  width: 3em;
  background-color: ${white};
  top: 0;
  filter: drop-shadow(2px 4px 6px ${black});
  transition: all 0.2s;
  &:hover{
    opacity: 0.2;
  }
`;

export const HomeBodyContainer = styled(Container)`
  background: transparent;
`;

export const HomeBannerContainer = styled(Overlay)`
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  flex-wrap: wrap;
  flex-direction: column;
 @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

export const HomeBanner = styled(NavLink)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 1em;
  flex-basis: 0;
  transition: all 0.4s;
  &:hover {
    box-shadow: ${navShadow};
  }
  @media ${device.mobileLarge} {
    flex-basis: 33%;
  }
`;

export const HomeBannerItemContainer = styled.section`
  width: 100%;
  @media ${device.mobileLarge} {
    width: 50%;
  }
`;

export const NewsAndEventMore = styled(NavLink)`
  background: ${navSubmenuColor};
  color: white;
  padding: 0.5em 2em;
  transition: all 0.4s;
  &:hover {
    background: ${enhancedNavLinkEnd};
  }
`;

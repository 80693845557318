import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_PC, HEADER_SG } from '../../common/translation';
import {
  CommonParagraph,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function PlanetClub(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>
          Planet Club: A Global Campaign for the Health of Our Planet
        </ParagraphHeader>
        <CommonParagraph>
          Planet Club is a visionary global campaign created to address the most
          pressing and challenging issues facing humanity today – the health and
          well-being of our planet, Earth. Recognizing the urgent need to protect
          our shared home, this initiative aims to unite individuals, communities,
          and organizations worldwide in a collective effort to combat environmental
          degradation, climate change, and other threats that endanger the Earth's ecosystems
          and, ultimately, the future of our species.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>The Mission</ParagraphHeader>
        <CommonParagraph>
          The primary mission of Planet Club is to promote, advocate, and
          implement initiatives that support the health and sustainability
          of our planet. This includes, but is not limited to, addressing
          climate change, conserving natural resources, protecting biodiversity,
          ensuring access to clean water, reducing pollution, and promoting sustainable development.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Membership-Based Initiative</ParagraphHeader>
        <CommonParagraph>
          Planet Club operates as a membership-based initiative, which means
          that individuals, institutions, and organizations can join the club
          by becoming members. This membership structure fosters a sense of
          belonging and a shared commitment to the cause. Here's a closer look
          at the features and benefits of being a part of the Planet Club:
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Global Network</ParagraphHeader>
        <CommonParagraph>
          By joining Planet Club, members become part of a vast and diverse network
          of individuals and organizations from all corners of the world. This network
          allows for the exchange of ideas, experiences, and best practices in addressing
          environmental issues on a global scale.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Access to Resources</ParagraphHeader>
        <CommonParagraph>
          Planet Club provides its members with access to valuable resources, including
          educational materials, research findings, and tools that facilitate environmental
          conservation and sustainability efforts. Members can tap into a wealth of
          information and guidance to make a positive impact in their own communities.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Collaborative Projects</ParagraphHeader>
        <CommonParagraph>
          As part of the club, members have the opportunity to participate in and contribute
          to collaborative projects designed to address specific environmental challenges.
          These projects may range from reforestation initiatives and waste reduction campaigns
          to climate change awareness programs.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Advocacy and Awareness</ParagraphHeader>
        <CommonParagraph>
          Planet Club is committed to raising awareness about critical environmental issues
          and advocating for change at local, national, and international levels. Members can
          participate in advocacy efforts, amplifying their voices and advocating for sustainable
          policies and practices.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Events and Activities</ParagraphHeader>
        <CommonParagraph>
          The club organizes and supports various events, workshops, and activities to engage
          members in hands-on environmental initiatives. These events can include tree-planting
          drives, clean-up campaigns, educational seminars, and more.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Inclusivity</ParagraphHeader>
        <CommonParagraph>
          Planet Club is open to all, regardless of age, gender, background, or expertise.
          It welcomes the involvement of individuals, families, schools, businesses, non-profits,
          and governments alike, recognizing that diverse perspectives and collaborations are key
          to addressing complex global challenges.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>The Impact</ParagraphHeader>
        <CommonParagraph>
          By uniting people and organizations through Planet Club, we can collectively make a
          substantial difference in addressing the critical issues affecting our planet's health.
          With a strong global network, shared knowledge, and a commitment to sustainable practices,
          Planet Club seeks to inspire positive change at all levels of society
          and promote a brighter,
          more sustainable future for all. Planet Club represents an inclusive, global movement that
          acknowledges the importance of safeguarding our planet for future generations. It invites
          everyone to be a part of the solution, creating a world where the health
          of our common home,
          Earth, is a top priority, and where sustainable practices and environmental consciousness
          are embraced by all.
        </CommonParagraph>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_PC)} content={content} />;
}

export default PlanetClub;

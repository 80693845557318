export const primaryColor = '#095460';
export const secondaryColor = '#f5f5f5';
export const navSubmenuColor = '#1d6baa';
export const white = 'white';
export const black = 'black';
export const greyBorder = '#e7e7e7';
export const navShadow = '0 3px 10px rgb(10 37 64 / 25%)';
export const footerShadow = '0px 0px 0.4em #ffffff9e';
export const facebook = '#38529a';
export const googlePlus = '#dd4d42';
export const twitter = '#1da1f2';
export const youtube = '#fe0000';
export const linkedin = '#0077b5';
export const enhancedNavLinkStart = '#3294a8';
export const enhancedNavLinkEnd = '#a432a8';
export const yellowEnhancedLink = '#ccd147';
export const pageTitleColor = '#00000096';

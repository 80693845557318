/* eslint-disable object-curly-newline */
import styled from 'styled-components';
import { Container } from '../common/style/index.style';
import { primaryColor, secondaryColor, white, black } from '../common/colors';
import { device } from '../MediaQuery/MediaQuery';
import { footerVines, footerLeaves } from '../common/imaes';

const container = styled(Container)`
  background-color: ${primaryColor};
  color: ${white};
`;

export const NavigationContainer = styled(container).attrs({
  as: 'nav',
})`
  background: none;
  grid-area: nav;
`;

export const ArticleContainer = styled(container).attrs({
  as: 'article',
})`
  color: ${black};
  background-color: ${secondaryColor};
  grid-area: article;
  font-weight: 100;
`;

export const FooterContainer = styled(container).attrs({
  as: 'footer',
})`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  background-image: url(${footerVines}), url(${footerLeaves});
  background-position: top right, top left;
  background-repeat: no-repeat;
  background-size: 13em;
  font-size: 0.9rem;
  @media ${device.tablet} {
    background-size: 15em;
    background-position: bottom right, top left;
  }
`;

export const CopyrightContainer = styled(container).attrs({
  as: 'aside',
})`
  background: transparent;
`;

export const SocialMediaContainer = styled(container).attrs({
  as: 'aside',
})`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background: transparent;
`;

export const FooterNavMenuContainer = styled(container).attrs({
  as: 'aside',
})`
  background: transparent;
`;

import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_CU, HEADER_SG } from '../../common/translation';
import {
  CommonParagraph,
  CommonParagraphContainer,
  ParagraphHeader,
} from '../../common/style/index.style';

function ChaurasiUdhyaan(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>
          Chaurasi Udhyaan: Celebrating a Spiritual Journey Beyond 84
        </ParagraphHeader>
        <CommonParagraph>
          Chaurasi Udhyaan is a profound and beautiful concept rooted
          in the timeless practice of spirituality, inspired by the revered
          age of 84. This concept draws from the ancient traditions of
          Sanatan spirituality, emphasizing the significance of reaching
          the age of 84 and the wisdom that accompanies this milestone. In
          the heart of Chaurasi Udhyaan lies the belief that when individuals
          reach this age, they have witnessed 1008 full moons and 12 sun cycles,
          a remarkable journey deserving of celebration.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>The Spiritual Significance</ParagraphHeader>
        <CommonParagraph>
          The number 84 holds special significance in the Sanatan tradition,
          representing the completeness of one's spiritual journey. Chaurasi
          Udhyaan acknowledges the wisdom and experience that individuals acquire
          by the age of 84, reflecting on the cycles of life, nature, and the
          cosmos. It is a tribute to the rich tapestry of existence that one
          has woven throughout their lifetime.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>The Concept</ParagraphHeader>
        <CommonParagraph>
         Chaurasi Udhyaan encourages people, upon reaching the age of 84, to embark
         on a new and healthful phase of aging that extends well beyond this remarkable
         age. The core of this concept is to celebrate this milestone by giving back to
         the Earth and nurturing its living beings. Specifically, individuals are encouraged
         to plant 84 new trees, nurture 84 existing ones, or take stewardship of 84 plants
         anywhere in the world, using a variety of methods and approaches.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Planting 84 Trees</ParagraphHeader>
        <CommonParagraph>
          To plant 84 trees is to acknowledge the importance of nurturing and giving life
          to the environment. Trees are not only symbolic of growth but also serve as guardians
           of the Earth, cleansing the air, providing shelter, and supporting biodiversity.
           Planting 84 trees is a gesture of commitment to the Earth's well-being and a legacy
           of life to future generations.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Nurturing 84 Plants</ParagraphHeader>
        <CommonParagraph>
          Nurturing 84 existing plants underscores the importance of continuity and care.
          It is a recognition of the interconnectedness of all life forms and the role we
          play in sustaining the beauty and balance of nature. By nurturing 84 plants,
          individuals ensure that the Earth's living tapestry thrives and flourishes.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Stewardship of 84 Plants</ParagraphHeader>
        <CommonParagraph>
          Taking stewardship of 84 plants, wherever they may be in the world, is an act of
          global citizenship. It is an acknowledgment that we are responsible for the health
          and well-being of the entire planet. By overseeing the care of 84 plants distributed
          across the globe, individuals contribute to the global ecosystem and participate in
          a collective effort to protect and preserve our natural heritage.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>Joining the Movement</ParagraphHeader>
        <CommonParagraph>
          To participate in Chaurasi Udhyaan and embark on this remarkable journey of
          celebrating your 84th birthday, while also nurturing the Earth and promoting
          healthful aging, click the link below. Join a global community of individuals
          who share the same reverence for the wisdom of age and the responsibility to
          sustain our beautiful planet. Together, we can create a legacy that extends
          well beyond our years, leaving a lasting impact for generations to come.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraph>
        Chaurasi Udhyaan is an embodiment of the harmony between the spiritual,
        the ecological, and the human, weaving together the threads of life's
        profound tapestry in a celebration of age, wisdom, and the everlasting beauty of our planet.
      </CommonParagraph>
    </>
  );
  return <GenericPage title={t(HEADER_CU)} content={content} />;
}

export default ChaurasiUdhyaan;

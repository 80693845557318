import styled from 'styled-components';
import type IconType from 'react-icons';
import { device } from '../../MediaQuery/MediaQuery';

export const ContentBody = styled.section`
  position: relative;
  z-index: 0;
  text-align: justify;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    background-image: url(/Images/logo.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    filter: opacity(0.2) blur(0.3em);
    z-index: -1;
    @media ${device.mobileSmall} {
      filter: opacity(0.2) blur(0.6em);
    }
    @media ${device.mobileLarge} {
      filter: opacity(0.2) blur(0.8em);
    }
    @media ${device.tablet} {
      filter: opacity(0.2) blur(1.3em);
    }
  }
`;

export const InformationTitle = styled.td`
  padding-right: 0.5em;
`;

export const InformationContainer = styled.tr`
  padding-top: 0.25em;
  padding-bottom: 0.25em;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SectionItemContainer = styled.section < {position: string}>`
  position: relative;
  margin: 0.9em;
  border-radius: 0.5em;
  text-align: center;
  border: 1px solid #37b44a;
  width: 10em;
  padding: 1.5em;
  &:after{
    ${(props) => (props.position ? `content: '${props.position}'` : '')};
    font-size: 12px;
    position: absolute;
    right: 1em;
    bottom: -0.5em;
    padding: 0.4em;
    border-radius: 10px;
    background: #37b44a;
    color: white;
  }

  @media ${device.mobileLarge} {
    width: 7em;
    padding: 1em;
    flex-direction: row;
  }

  @media ${device.tablet} {
    width: 9.7em;
    padding: 1.3em;
  }
`;

export const UnifiedSectionContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 0.5em;
  border-radius: 0.5em;
  border: 1px solid #37b44a;
  margin: 0.5em;
  @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

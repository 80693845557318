import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import GenericPage from '../Generic';
import { HEADER_VOLUNTEER } from '../../common/translation';
import { VolunteersContainer } from './index.style';
import {
  FormContainer,
  FormField,
  InputFieldContainer,
  FormFieldTextArea,
} from '../../common/style/index.style';
import InputField from '../../Component/InputField';

function Volunteer(): JSX.Element {
  const [t] = useTranslation();
  const formFields = [
    {
      title: 'Full Name',
      type: 'text',
      placeHolder: 'Your Name ...',
      autoFocus: true,
    },
    {
      title: 'Email Address',
      type: 'text',
      placeHolder: 'Your Email ...',
    },
    {
      title: 'Phone Number',
      type: 'text',
      placeHolder: 'Your Phone ...',
    },
    {
      title: 'Address',
      type: 'text',
      placeHolder: 'Your Address ...',
    },
  ];

  const MappedFormFields = formFields.map((elem) => (
    <InputField
      title={elem.title}
      type={elem.type}
      placeHolder={elem.placeHolder}
      autoFocus={elem.autoFocus}
      key={uuidv4()}
    />
  ));

  const content = (
    <VolunteersContainer>
      <FormContainer action="/action_page.php">
        <fieldset style={{ padding: '1em' }}>
          <legend style={{ fontWeight: 700 }}>Order Form</legend>
          {MappedFormFields}
          <InputFieldContainer>
            <label style={{ fontWeight: 700 }} htmlFor={'message'}>
              Additional Message
            </label>
            <FormFieldTextArea
              id="message"
              placeholder="Message to convey ..."
            />
          </InputFieldContainer>
          <InputFieldContainer
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormField type="submit" value="Submit" />
          </InputFieldContainer>
        </fieldset>
      </FormContainer>
    </VolunteersContainer>
  );
  return <GenericPage title={t(HEADER_VOLUNTEER)} content={content} />;
}

export default Volunteer;

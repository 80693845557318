import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { IoLocationSharp } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import GenericPage from '../Generic';
import { HEADER_CONTACT } from '../../common/translation';
import {
  FormContainer,
  FormField,
  InputFieldContainer,
  FormFieldTextArea,
} from '../../common/style/index.style';
import InputField from '../../Component/InputField';
import {
  ContactContainer,
  ContactInformationContainer,
  MainContactContainer,
  ContactInformationContentContainer,
  ContactInformationContent,
  ContactInformationDetail,
} from './index.style';

function Contact(): JSX.Element {
  const [t] = useTranslation();
  const formFields = [
    {
      title: 'Full Name',
      type: 'text',
      placeHolder: 'Your Name ...',
      autoFocus: true,
    },
    {
      title: 'Email Address',
      type: 'text',
      placeHolder: 'Your Email ...',
    },
    {
      title: 'Phone Number',
      type: 'text',
      placeHolder: 'Your Phone ...',
    },
    {
      title: 'Subject',
      type: 'text',
      placeHolder: 'Subject ...',
    },
  ];

  const MappedFormFields = formFields.map((elem) => (
    <InputField
      title={elem.title}
      type={elem.type}
      placeHolder={elem.placeHolder}
      autoFocus={elem.autoFocus}
      key={uuidv4()}
    />
  ));

  const content = (
    <MainContactContainer>
      <ContactInformationContainer>
        <ContactInformationContentContainer>
          <ContactInformationContent
            target="_blank"
            href="https://www.google.com/maps/place/2601+Bluewater+Ct,+Marina,+CA+93933,+USA/@36.6616105,-121.8088937,17z/data=!3m1!4b1!4m6!3m5!1s0x808dfd21a19352b3:0x8cb1d2d84278c2e5!8m2!3d36.6616105!4d-121.8088937!16s%2Fg%2F11fk7zk3q9?entry=ttu"
          >
            <IoLocationSharp
              style={{
                padding: '0.5rem',
                fontSize: '5rem',
              }}
            />
            <section style={{ width: '100%', padding: '1em' }}>
              <h3 style={{ padding: '1em 0em' }}>Address</h3>
              <ContactInformationDetail>
                2601 Bluewater Ct. Marina, CA, 93933, USA
              </ContactInformationDetail>
              {/* <ContactInformationDetail>
                P.O. Box: 8975 EPC 5010
              </ContactInformationDetail> */}
              <ContactInformationDetail>
                Kathmandu. NEPAL
              </ContactInformationDetail>
            </section>
          </ContactInformationContent>
        </ContactInformationContentContainer>
        <ContactInformationContentContainer>
          <ContactInformationContent
            target="_blank"
            href="tel: +1 510-253-4026"
          >
            <BsFillTelephoneFill
              style={{
                padding: '1rem',
                fontSize: '5rem',
              }}
            />
            <section style={{ width: '100%', padding: '1em' }}>
              <h3 style={{ padding: '1em 0em' }}>Phone</h3>
              <ContactInformationDetail>
                +1 510-253-4026
              </ContactInformationDetail>
            </section>
          </ContactInformationContent>
        </ContactInformationContentContainer>
        <ContactInformationContentContainer>
          <ContactInformationContent
            target="_blank"
            href="mailto: savetheplanetglobal2030@gmail.com"
          >
            <MdEmail
              style={{
                padding: '1rem',
                fontSize: '5rem',
              }}
            />
            <section style={{ width: '100%', padding: '1em' }}>
              <h3 style={{ padding: '1em 0em' }}>Email</h3>
              <ContactInformationDetail>
                savetheplanetglobal2030@gmail.com
              </ContactInformationDetail>
            </section>
          </ContactInformationContent>
        </ContactInformationContentContainer>
      </ContactInformationContainer>
      <ContactContainer>
        <FormContainer
          action="/action_page.php"
          style={{
            width: '100%',
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        >
          <fieldset style={{ padding: '1em' }}>
            <legend style={{ fontWeight: 700 }}>Contact Information</legend>
            {MappedFormFields}
            <InputFieldContainer>
              <label style={{ fontWeight: 700 }} htmlFor={'message'}>
                Additional Message
              </label>
              <FormFieldTextArea
                id="message"
                placeholder="Message to convey ..."
              />
            </InputFieldContainer>
            <InputFieldContainer
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormField type="submit" value="Submit" />
            </InputFieldContainer>
          </fieldset>
        </FormContainer>
        <iframe
          title="Save The Planet on Google maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3200.5360498513874!2d-121.80889369999998!3d36.66161050000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808dfd21a19352b3%3A0x8cb1d2d84278c2e5!2s2601%20Bluewater%20Ct%2C%20Marina%2C%20CA%2093933%2C%20USA!5e0!3m2!1sen!2snp!4v1697255802698!5m2!1sen!2snp"
          style={{
            width: '100%',
            height: 'auto',
            border: 0,
            padding: '1em 0em',
          }}
          loading="lazy"
        ></iframe>
      </ContactContainer>
    </MainContactContainer>
  );
  return <GenericPage title={t(HEADER_CONTACT)} content={content} />;
}

export default Contact;

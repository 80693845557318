/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteInformation, StoreProps } from '../TsTypes';
import {
  getSiteInformation,
} from '../Service/home';

enum currentStatus {
  idle = 'idle',
  loading = 'loading',
}

export const initialState = {
  status: currentStatus.idle,
  user: {
    id: null,
    username: null,
    email: null,
    confirmedEmail: false,
    role: {
      id: null,
      name: null,
      description: null,
      aclList: null,
    },
  },
  siteInformation: {
    name: null,
    copyright: null,
    poweredBy: null,
    contact: null,
    phone: null,
    email: null,
    poBox: null,
    location: null,
    externalMapsURL: null,
    frameMapURL: null,
    socialMedias: null,
  },
  error: null,
} as StoreProps;

const counterSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    loadError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    logout(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiteInformation.pending, (state) => {
        state.status = currentStatus.loading;
      })
      .addCase(getSiteInformation.rejected, (state, action) => {
        state.error = action.payload as string;
        state.status = currentStatus.idle;
      })
      .addCase(getSiteInformation.fulfilled, (state, action) => {
        state.error = '';
        state.siteInformation = { ...action.payload.payload } as SiteInformation;
        state.status = currentStatus.idle;
      });
  },
});

export const { loadError, logout } = counterSlice.actions;
export default counterSlice.reducer;

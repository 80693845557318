import {
  GalleryContainer,
  DescriptionContainer,
  ReadMoreBth,
  DescriptionContent,
  GalleryContainerImage,
} from './index.style';

const GalleryCard = ({
  thumbnailURL,
  title,
  url = '/',
}: {
  thumbnailURL?: string;
  title?: string;
  url?: string;
}) => (
  <GalleryContainer>
    {thumbnailURL && <GalleryContainerImage src={thumbnailURL} alt={title} />}
    <DescriptionContainer>
      {title && <DescriptionContent>{title}</DescriptionContent>}
      <ReadMoreBth to={url}>View More</ReadMoreBth>
    </DescriptionContainer>
  </GalleryContainer>
);

export default GalleryCard;

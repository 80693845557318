const size = {
  mobileSmall: '576px',
  mobileLarge: '768px',
  tablet: '992px',
  laptop: '1200px',
};

export const device = {
  mobileSmall: `(min-width: ${size.mobileSmall})`,
  mobileLarge: `(min-width: ${size.mobileLarge})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
};

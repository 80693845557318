import { MdEmail } from 'react-icons/md';
import {
  NameHeading,
  FigureContainer,
  IndividualCardContainer,
  IndividualCardOverlay,
  DescriptionContainer,
  IndividualEmail,
} from './index.style';

const TeamIndividualCard = ({
  image,
  name,
  position,
  email,
}: {
  image?: string;
  name?: string;
  position?: string;
  email?: string;
}) => (
  <IndividualCardContainer className="IndividualCardContainer">
    <IndividualCardOverlay>
      <NameHeading>{name}</NameHeading>
      <FigureContainer>
        <img style={{ width: '100%' }} src={image} alt={name} />
      </FigureContainer>
      <DescriptionContainer>
        {position && <span>{position}</span>}
        {email && (
          <IndividualEmail href={`mailto: ${email}`}>
            <MdEmail style={{ paddingRight: '0.5em' }} /> {email}
          </IndividualEmail>
        )}
      </DescriptionContainer>
    </IndividualCardOverlay>
  </IndividualCardContainer>
);

export default TeamIndividualCard;

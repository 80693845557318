import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiOutlineGooglePlus,
} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { SocialMediaContainer, SocialMediaTitle } from './index.style';
import {
  facebook,
  googlePlus,
  twitter,
  youtube,
  linkedin,
} from '../../common/colors';
import {
  ariaFacebook,
  ariaGooglePlus,
  ariaTwitter,
  ariaYoutube,
  ariaLinkedin,
} from '../../common/aria';
import { FOOTER_SOCIAL_MEDIA_FOLLOW } from '../../common/translation';
import {
  FOOTER_SOCIAL_FACEBOOK,
  FOOTER_SOCIAL_TWITTER,
  FOOTER_SOCIAL_LINKEDIN,
  FOOTER_SOCIAL_GOOGLE,
  FOOTER_SOCIAL_YOUTUBE,
} from '../../common/externalLinks';
import SocialMediaIcon from './SocialMediaIcon';

const SocialMedia = () => {
  const [t] = useTranslation();

  const SocialMediaIcons = [
    {
      socialMediaHyperlink: FOOTER_SOCIAL_FACEBOOK,
      hoverColor: facebook,
      icon: (
        <FaFacebookF
          aria-label={ariaFacebook}
          style={{
            height: '1em',
            width: '1em',
            padding: '0.5em',
          }}
        />
      ),
    },
    {
      socialMediaHyperlink: FOOTER_SOCIAL_TWITTER,
      hoverColor: twitter,
      icon: (
        <AiOutlineTwitter
          aria-label={ariaTwitter}
          style={{
            height: '1em',
            width: '1em',
            padding: '0.5em',
          }}
        />
      ),
    },
    {
      socialMediaHyperlink: FOOTER_SOCIAL_YOUTUBE,
      hoverColor: youtube,
      icon: (
        <AiFillYoutube
          aria-label={ariaYoutube}
          style={{
            height: '1em',
            width: '1em',
            padding: '0.5em',
          }}
        />
      ),
    },
    {
      socialMediaHyperlink: FOOTER_SOCIAL_GOOGLE,
      hoverColor: googlePlus,
      icon: (
        <AiOutlineGooglePlus
          aria-label={ariaGooglePlus}
          style={{
            height: '1em',
            width: '1em',
            padding: '0.5em',
          }}
        />
      ),
    },
    {
      socialMediaHyperlink: FOOTER_SOCIAL_LINKEDIN,
      hoverColor: linkedin,
      icon: (
        <FaLinkedinIn
          aria-label={ariaLinkedin}
          style={{
            height: '1em',
            width: '1em',
            padding: '0.5em',
          }}
        />
      ),
    },
  ];

  const SocialMediaIconMap = SocialMediaIcons.map((elem, index) => (
    <SocialMediaIcon
      socialMediaHyperlink={elem.socialMediaHyperlink}
      hoverColor={elem.hoverColor}
      key={uuidv4()}
      index={index.toString()}
    >
      {elem.icon}
    </SocialMediaIcon>
  ));
  return (
    <SocialMediaContainer className={'SocialMediaContainer'}>
      <section
        style={{
          flexGrow: 1,
          alignItems: 'center',
          display: 'flex',
          padding: '1em',
          justifyContent: 'center',
        }}
      >
        <SocialMediaTitle>{t(FOOTER_SOCIAL_MEDIA_FOLLOW)}</SocialMediaTitle>
      </section>
      <section
        style={{
          flexGrow: 1,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        {SocialMediaIconMap}
      </section>
    </SocialMediaContainer>
  );
};

export default SocialMedia;

import React from 'react';
import NavigationContent from './navigationContent';

const Navigarion: React.FunctionComponent = () => (
  <>
    <NavigationContent />
  </>
);

export default Navigarion;

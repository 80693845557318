import { useTranslation } from 'react-i18next';
import GenericPage from '../Generic';
import { HEADER_OG } from '../../common/translation';
import {
  CommonListItem,
  OrderedCommonList,
  CommonParagraphContainer,
  ParagraphHeader,
  CommonParagraph,
  CommonSubParagraphTitle,
} from '../../common/style/index.style';

function OurGoalsAndObjective(): JSX.Element {
  const [t] = useTranslation();
  const content = (
    <>
      <CommonParagraphContainer>
        <ParagraphHeader>GOALS</ParagraphHeader>
        <CommonParagraph>
          <CommonSubParagraphTitle>
            Preserve and Promote Endangered Flora and Fauna
          </CommonSubParagraphTitle>:
          To safeguard and promote endangered species of animals and plants.
        </CommonParagraph>
        <CommonParagraph>
          <CommonSubParagraphTitle>
            Empower All Communities for Biodiversity Conservation
          </CommonSubParagraphTitle>:
          Empower individuals across all social strata and geographic
          regions to actively engage in the preservation of biological diversity.
        </CommonParagraph>
        <CommonParagraph>
          <CommonSubParagraphTitle>
            Enhance Plant Communities with Sustainable Practices</CommonSubParagraphTitle>:
          Conserve, develop, and expand plant communities using sustainable
          and ecologically responsible methods.
        </CommonParagraph>

        <CommonParagraph>
          <CommonSubParagraphTitle>
            Uphold Livelihood Principles for Natural Resource Conservation
          </CommonSubParagraphTitle>:
          Apply fundamental principles of sustainable livelihoods to conserve
          natural resources and their utility.
        </CommonParagraph>

        <CommonParagraph>
          <CommonSubParagraphTitle>
            Establish Non-Profit Initiatives to Restore Natural Equilibrium
          </CommonSubParagraphTitle>:
          Create non-profit initiatives that utilize educational institutions,
          healthcare facilities, and public parks to educate and comfort diverse
          communities, helping maintain a natural balance.
        </CommonParagraph>

        <CommonParagraph>
          <CommonSubParagraphTitle>
            Execute Global Environmental Conservation Programs by 2030
          </CommonSubParagraphTitle>:
          Implement worldwide programs aimed at achieving environmental balance
          and conservation by the year 2020.
        </CommonParagraph>

        <CommonParagraph>
          <CommonSubParagraphTitle>
            Establish and Operate Research Centers for Local Resource Sustainability
          </CommonSubParagraphTitle>:
          Establish and operate research centers focused on promoting natural
          resources that can be sustainably produced in specific regions,
          while advocating for human rights.
        </CommonParagraph>

        <CommonParagraph>
          <CommonSubParagraphTitle>
            Empower Women and Children for Sustainable Income Generation</CommonSubParagraphTitle>:
          Develop and implement targeted self-reliance programs that harness
          the potential of women and children, raising awareness about income
          generation through environmental conservation.
        </CommonParagraph>
      </CommonParagraphContainer>
      <CommonParagraphContainer>
        <ParagraphHeader>OBJECTIVES:</ParagraphHeader>
        <OrderedCommonList>
          <CommonListItem>
            To conserve and promote extinctive animals and plants.
          </CommonListItem>
          <CommonListItem>
            To empower people of all classes, levels and areas to conserve
            biological diversities.
          </CommonListItem>
          <CommonListItem>
            To conserve, develop and widen plant community.
          </CommonListItem>
          <CommonListItem>
            Use fundamental principle of livelihood to conserve natural
            resources and utility.
          </CommonListItem>
          <CommonListItem>
            To construct profitless, to keep natural balance by utilizing the
            resources of educational institutions, health&nbsp;posts and develop
            public parks to inform and comfort to different community.
          </CommonListItem>
          <CommonListItem>
            To conduct world-wide programmes related to environmental balance
            and conserve by 2020 A.D.
          </CommonListItem>
          <CommonListItem>
            To establish and operate centers in research of natural resources
            that can be produced in the concerned&nbsp;areas by advocating and
            supporting Human Rights.
          </CommonListItem>
          <CommonListItem>
            To conduct specific self dependence program focusing women’s and
            children’s potential and aware them to&nbsp;generate income by
            maintaining the environmental conservation.
          </CommonListItem>
        </OrderedCommonList>
      </CommonParagraphContainer>
    </>
  );
  return <GenericPage title={t(HEADER_OG)} content={content} />;
}

export default OurGoalsAndObjective;

/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-globals */
import { NavLink, useLocation } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import {
  NavLogo,
  FloatingNavContainer,
  FloatingNavOverlay,
  MenuButton,
  FloatingNavContent,
  NavClickMenuContainer,
  NavClickMenuOverlay,
} from './index.style';
import { Overlay, Figure } from '../../common/style/index.style';
import { device } from '../../MediaQuery/MediaQuery';
import NavItem from './navItem';
import { menu, home } from '../../common/aria';
import { ROOT } from '../../common/routes';
import { logo } from '../../common/imaes';

const NavigationContent: React.FunctionComponent = () => {
  const currentLocation = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isTabletUp = useMediaQuery({
    query: device.tablet,
  });
  const [t] = useTranslation();
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: false,
  });

  useEffect(() => {
    if (isTabletUp) {
      setIsMenuOpen(false);
    }
  }, [isTabletUp]);

  useEffect(() => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  }, [currentLocation]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('disableOverflow');
    } else {
      document.body.classList.remove('disableOverflow');
    }
  }, [isMenuOpen]);

  const orgLogo = logo; // PROBABLY FROM STORE

  const handleMenuOpen = (event: any) => {
    setIsMenuOpen(!isMenuOpen);
    event.preventDefault();
  };

  const handleMenuBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isMenuOpen) {
        setIsMenuOpen(!isMenuOpen);
      }
    }
    event.preventDefault();
  };

  const MemoizedNavItem = React.useMemo(() => <NavItem
    inView={
      (isMenuOpen && (!isMenuOpen).toString()) ||
                  (typeof inView !== 'undefined' && inView.toString()) ||
                  true.toString()
    }/>, [isMenuOpen, inView]);

  return (
    <>
      <Overlay style={{ opacity: '0', position: 'absolute' }} ref={ref}>
        <Figure>
          <NavLogo src={orgLogo} alt={t('NAV.NAV_STP')} />
        </Figure>
      </Overlay>
      <FloatingNavContainer onBlur={ handleMenuBlur }
        inView={
          (isMenuOpen && (!isMenuOpen).toString()) ||
                  (typeof inView !== 'undefined' && inView.toString()) ||
                  true.toString()
        }>
        <FloatingNavOverlay
          inView={
            (typeof inView !== 'undefined' && inView.toString()) ||
            true.toString()
          }
          className={'FloatingNavOverlay'}
        >
          <FloatingNavContent>
            <NavLink to={ROOT} aria-label={home}>
              <Figure>
                <NavLogo src={orgLogo} alt={t('NAV.NAV_STP')} />
              </Figure>
            </NavLink>
            {!isTabletUp && (
              <MenuButton onClick={handleMenuOpen} aria-label={menu}>
                <MdMenu style={{ color: 'white' }} />
              </MenuButton>
            )}
          </FloatingNavContent>
          {(isTabletUp || isMenuOpen) && (
            <NavClickMenuContainer isTabletUp={ isTabletUp }>
              <NavClickMenuOverlay className={ 'NavClickMenuOverlay' }
                inView={
                  (isMenuOpen && (!isMenuOpen).toString()) ||
                  (typeof inView !== 'undefined' && inView.toString()) ||
                  true.toString()
                }
              >
                {MemoizedNavItem}
              </NavClickMenuOverlay>
            </NavClickMenuContainer>
          )}
        </FloatingNavOverlay>
      </FloatingNavContainer>
    </>
  );
};

export default NavigationContent;

/* eslint-disable object-curly-newline */
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import {
  FooterColumnContainer,
  FooterColumnTitle,
  FooterColumnContent,
} from './index.style';
import { footerStructure } from '../../TsTypes';
import FooterButton from './FooterButton';

const FooterColumn = ({ title, navChildrens, index }: footerStructure) => {
  const [t] = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
    initialInView: false,
  });

  const footerButtons = navChildrens.map((elem) => (
    <FooterButton
      key={uuidv4()}
      title={elem.title}
      url={elem.url}
      external={elem.external}
      icon={elem.icon}
      index={index}
    />
  ));
  return (
    <FooterColumnContainer
      index={index?.toString()}
      aria-labelledby={`FooterColumn${title}`}
      ref={ref}
      inView={
        (typeof inView !== 'undefined' && inView.toString()) || true.toString()
      }
    >
      <FooterColumnTitle id={`FooterColumn${title}`}>
        {title && t(title)}
      </FooterColumnTitle>
      <FooterColumnContent>{footerButtons}</FooterColumnContent>
    </FooterColumnContainer>
  );
};
export default FooterColumn;

/* eslint-disable @typescript-eslint/ban-types */
import { MouseEventHandler, ReactNode } from 'react';

export interface AppProps {
  t: Function;
}

export interface FooterNavChildrens {
  title?: string;
  url?: string;
  external?: boolean;
  icon?: JSX.Element;
  index?: number;
}

export interface footerStructure {
  title?: string;
  index?: number;
  navChildrens: FooterNavChildrens[];
}

interface navCommon {
  title: string;
  url?: string | undefined;
  translate?: boolean;
  index?: number;
  icon?: JSX.Element;
  focus?: string;
}

export interface NestedNavItems extends navCommon {
  navChildren?: NestedNavItems[] | undefined;
  inView?: string;
}

export interface RootNavStyleProp {
  isMenuOpen?: boolean;
  inView?: string;
}

export interface NavMenuProp {
  inView?: string;
  isDisplaying?: boolean;
  isTabletUp?: boolean;
}

export interface RootNavProp {
  title?: string;
  children?: Array<ReactNode> | ReactNode | undefined;
  url?: string;
}

export interface NavItemProp {
  title?: string;
  navChildren?: Array<NavItemProp>;
  url?: string;
}

export interface PageHeaderProps {
  children: object;
}

export interface PageHeaderContentProps {
  title: string;
  children: object;
}

export interface LocaleProps {
  abbreviatedLocale: string;
  locale: string;
}

export interface ActiveLocaleProps {
  active: boolean;
}

export interface AddButtonProps {
  clickEvent: MouseEventHandler;
  children: object;
}

export interface TextBoxProps {
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  label: string;
  inputValue: string;
  changeValue: Function;
  isRequired?: boolean;
  focus?: boolean;
  type?: string;
}

export interface ACL{
  acl: string | null | undefined;
}
export interface role {
  id: number | null | undefined;
  name: string | null | undefined;
  description: string | null | undefined;
  aclList: [ACL] | undefined | null;
}

export interface profile {
  id: number | null;
  full_name: string;
  user: number | null;
}

export interface User {
  id: number | null | undefined;
  username: string | null | undefined;
  email: string | null | undefined;
  role: role | null | undefined;
  confirmedEmail: boolean | null | undefined;
}

export interface NavigationProp {
  children?: Array<ReactNode> | ReactNode | Element;
}

export interface LoadingProp {
  isLoading: boolean;
}

export interface LoginApiProp {
  userName: string;
  password: string;
}

export interface RouterProp {
  children: JSX.Element;
  path: string;
  exact: boolean;
  token: string | boolean;
}

export interface SocialMedias {
  name: string | null | undefined;
  url: string | null | undefined;
}

export interface SiteInformation{
    name: string | null | undefined,
    copyright: string | null | undefined,
    poweredBy: string | null | undefined,
    contact: string | null | undefined,
    phone: string | null | undefined,
    email: string | null | undefined,
    poBox: string | null | undefined,
    location: string | null | undefined,
    externalMapsURL: string | null | undefined,
    frameMapURL: string | null | undefined,
    socialMedias: [SocialMedias] | null | undefined,
}

export interface StoreProps {
  status: string | undefined | null;
  user: User | undefined | null;
  error: string | undefined | null;
  siteInformation: SiteInformation | null | undefined;
}

// eslint-disable-next-line no-shadow
export enum CurrentPageType {
  testemonials = 'Testemonials',
  afforestation = 'Afforestation',
  programme = 'Programmes',
}

/* eslint-disable operator-linebreak */
import { v4 as uuid } from 'uuid';
import { useEffect, useState } from 'react';
import {
  GalleryDetailContainer,
  GalleryImage,
  GalleryCarouselContainer,
} from './index.style';
import { CommonParagraphContainer } from '../../../common/style/index.style';
import GenericPage from '../../Generic';
import Carousel from '../../../Component/Carousel';
import { CurrentPageType } from '../../../TsTypes';

const DetailsPageData = {
  Testemonials: [
    {
      url: 'https://dflfmau07swcr.cloudfront.net/vL5_7yaC4E160321085455.jpg',
      alternate: CurrentPageType.testemonials,
      displayMax: true.toString(),
    },
  ],
  Afforestation: [
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0016.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0020.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0021.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0022.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0023.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0024.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0026.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0036.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0038.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0039.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0070.jpeg',
      alternate: CurrentPageType.afforestation,
      displayMax: true.toString(),
    },
  ],
  Programmes: [

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0008.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0013.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0028.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0031.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0042.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0046.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0049.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0052.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0065.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0071.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0074.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0079.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0083.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0009.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0014.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0029.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0033.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0044.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0047.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0050.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0053.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0066.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0072.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0077.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0080.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0084.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0011.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0025.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0030.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0034.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0045.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0048.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0051.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0054.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0068.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0073.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0078.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

    {
      url: 'https://dflfmau07swcr.cloudfront.net/IMG_0082.jpeg',
      alternate: CurrentPageType.programme,
      displayMax: true.toString(),
    },

  ],
};

const GalleryDetail = ({ title }: {title: CurrentPageType}): JSX.Element => {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const handleImageClick = (elementIndex: number) => {
    setIsImageOpen(!isImageOpen);
    setSelectedItem(elementIndex);
  };

  const clickHandle = (event: any) => {
    if (
      !(
        event.target.id === 'desiredCarouselClick' ||
        event.target.className === 'control-dots' ||
        event.target.className === 'dot selected' ||
        event.target.nodeName.toUpperCase() === 'SVG' ||
        event.target.nodeName.toUpperCase() === 'PATH' ||
        event.target.nodeName.toUpperCase() === 'IMG'
      )
    ) {
      window.removeEventListener('click', clickHandle);
      setIsImageOpen(!isImageOpen);
    }
  };

  useEffect(() => {
    if (isImageOpen) {
      document.body.classList.add('disableOverflow');
      window.addEventListener('click', clickHandle);
    } else {
      document.body.classList.remove('disableOverflow');
    }
  }, [isImageOpen]);

  useEffect(() => {
    const handlePopHistory = () => {
      window.removeEventListener('click', clickHandle);
      window.removeEventListener('popstate', handlePopHistory);
      document.body.classList.remove('disableOverflow');
    };

    window.addEventListener('popstate', handlePopHistory);
  }, []);

  const MappedImages = DetailsPageData[`${title}`].map((elem, elementIndex) => (
    <GalleryImage
      onClick={ () => handleImageClick(elementIndex) }
      src={ elem.url }
      alt={ elem.alternate || 'Testimonial' }
      key={ uuid() }
    />
  ));

  const content = (
    <>
      <CommonParagraphContainer>
        <GalleryDetailContainer>{ MappedImages }</GalleryDetailContainer>
        { isImageOpen && (
          <GalleryCarouselContainer isItemClicked={ isImageOpen.toString() }>
            <Carousel
              images={ DetailsPageData[`${title}`] }
              isImageOpen={ isImageOpen.toString() }
              selectedItem={ selectedItem }
            />
          </GalleryCarouselContainer>
        ) }
      </CommonParagraphContainer>
    </>
  );

  return (
    <GenericPage
      title={title}
      content={ content }
      disableContentAnimation={ isImageOpen.toString() }
    />
  );
};

export default GalleryDetail;

import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
  SocialMediaIconContainer,
  SocialMediaIconOverlay,
} from './index.style';

const SocialMediaIcon = ({
  socialMediaHyperlink,
  hoverColor,
  children,
  index,
}: {
  socialMediaHyperlink?: string;
  hoverColor?: string;
  children?: React.ReactNode;
  index?: string;
}) => {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
    initialInView: false,
  });
  return (
    <SocialMediaIconContainer
      ref={ref}
      inView={
        (typeof inView !== 'undefined' && inView.toString()) || true.toString()
      }
      index={index}
    >
      <SocialMediaIconOverlay
        href={socialMediaHyperlink}
        color={hoverColor || 'white'}
        target="_blank"
      >
        {children}
      </SocialMediaIconOverlay>
    </SocialMediaIconContainer>
  );
};

export default SocialMediaIcon;

/* eslint-disable indent */
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Overlay } from '../../common/style/index.style';
import {
 primaryColor, white, black, footerShadow,
} from '../../common/colors';
import { device } from '../../MediaQuery/MediaQuery';

const selectedFooterSlideLeft = keyframes`
  0% {
    transform: translateX(0em);
  }
  100% {
    transform: translateX(-0.5em);
  }
`;

const selectedFooterSlideRight = keyframes`
  0% {
    transform: translateX(0em);
  }
  100% {
    transform: translateX(0.5em);
  }
`;

const selectedFooterGrow = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

const FooterContainerSlideRight = keyframes`
  0% {
    transform: translateX(-0.9em);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0em);
  }
`;

const FooterContainerSlideLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0.9em);
  }
  100% {
    opacity: 1;
    transform: translateX(0em);
  }
`;

const FooterContainerSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-0.9em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
`;

export const FooterContainer = styled(Overlay)`
  background-color: ${primaryColor};
  display: flex;
  flex-grow: 1;
  padding: 1em 1em;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  background: transparent;
  &:before {
    content: '';
    padding-top: 1em;
    padding-left: 10em;
    position: absolute;
    left: -1.5em;
    border-top: 1px solid red;
    border-bottom: 1px solid red;
  }
  &:after {
    content: '';
    padding-top: 10em;
    padding-left: 1em;
    position: absolute;
    left: 0em;
    border-left: 1px solid red;
    border-right: 1px solid red;
    top: -0.5em;
  }
  @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

export const FooterColumnTitle = styled.h3`
  padding: 0.75em;
  align-items: start;
  border-bottom: 2px solid ${white};
  color: white;
`;

export const FooterColumnContainer = styled(Overlay)<{
  index?: string;
  inView?: string;
}>`
  background-color: ${primaryColor};
  padding: 1em 0.5em;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  background: transparent;
  flex-basis: 30%;
  opacity: ${(prop) => {
    if (!prop.index) {
      return '1';
    }
    return '0';
  }};
  animation: ${(prop) => {
      if (!prop.inView || !(prop.inView === 'true') || !prop.index) {
        return 'none';
      }
      if (prop.index === '0') {
        return FooterContainerSlideRight;
      }
      if (prop.index === '1') {
        return FooterContainerSlideDown;
      }
      if (prop.index === '2') {
        return FooterContainerSlideLeft;
      }
      return 'none';
    }}
    1s forwards;
  @media ${device.mobileLarge} {
    align-items: ${(prop) => {
      if (prop.index === '1') {
        return 'center;';
      }
      if (prop.index === '2') {
        return 'flex-end;';
      }
      return 'flex-start;';
    }};
  }
`;

export const FooterColumnContent = styled(Overlay)`
  background: transparent;
  padding: 0.5em;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
`;

export const FooterColumnContentText = styled(Overlay)<{ index?: number }>`
  padding: 0.4em;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  @media ${device.mobileLarge} {
    justify-content: ${(prop) => {
      if (prop.index === 1) {
        return 'center;';
      }
      if (prop.index === 2) {
        return 'flex-end;';
      }
      return 'flex-start;';
    }};
  }
`;

export const FooterLinks = styled(NavLink)<{ index?: number }>`
  display: flex;
  color: ${white};
  width: 100%;
  padding: 0.2em;
  transition: all 0.4s;
  border-radius: 4px;
  &:hover {
    color: ${black};
    background-color: ${white};
    animation: ${selectedFooterSlideRight} 0.3s forwards;
    box-shadow: ${footerShadow};
    @media ${device.mobileLarge} {
      animation: ${(prop) => {
          if (prop.index === 0) {
            return selectedFooterSlideRight;
          }
          if (prop.index === 1) {
            return selectedFooterGrow;
          }
          return selectedFooterSlideLeft;
        }}
        0.3s forwards;
    }
  }
`;

export const ExternalFooterLinks = styled.a<{ index?: number; }>`
  display: flex;
  color: ${white};
  width: 100%;
  padding: 0.2em;
  transition: all 0.4s;
  border-radius: 4px;
  &:hover {
    color: ${black};
    background-color: ${white};
    animation: ${selectedFooterSlideRight} 0.3s forwards;
    box-shadow: ${footerShadow};
    @media ${device.mobileLarge} {
      animation: ${(prop) => {
          if (prop.index === 0) {
            return selectedFooterSlideRight;
          }
          if (prop.index === 1) {
            return selectedFooterGrow;
          }
          return selectedFooterSlideLeft;
        }}
        0.3s forwards;
    }
  }
`;

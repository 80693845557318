import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Overlay } from '../../common/style/index.style';
import { navSubmenuColor, enhancedNavLinkEnd, navShadow } from '../../common/colors';
import { device } from '../../MediaQuery/MediaQuery';

export const LinkCardContainer = styled(Overlay)`
  height: 15em;
  display: flex;
  flex-grow: 0;
  width: 100%;
  padding: 0;
  @media ${device.mobileLarge} {
    width: 50%;
  }
`;

export const EventDate = styled.div`
  transition: all 0.4s;
  padding: 0.5em 1em;
  display: inline-block;
  background: ${enhancedNavLinkEnd};
  color: white;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
`;

export const EventDetailsButton = styled.span`
  padding: 0.2em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${navSubmenuColor};
`;

export const EventLink = styled(NavLink)`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  padding: 1em;
  transition: all 0.4s;
  &:hover {
    box-shadow: ${navShadow};
  }
  &:hover .eventDate {
    padding-left: 2em;
    background: ${enhancedNavLinkEnd};
  }
  &:hover .eventTitle {
    font-weight: 700;
  }
`;

import styled, { keyframes } from 'styled-components';
import { device } from '../../../MediaQuery/MediaQuery';
import { greyBorder, secondaryColor } from '../../../common/colors';

const PopIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const GalleryDetailContainer = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 0.5em;
  @media ${device.mobileLarge} {
    flex-direction: row;
  }
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: auto;
  border: 1px solid ${greyBorder};
  @media ${device.mobileLarge} {
    width: 45%;
  }
  @media ${device.tablet} {
    width: 31%;
  }
`;

export const GalleryCarouselContainer = styled.section<{
  isItemClicked?: string;
}>`
  display: ${(prop) => {
    if (prop.isItemClicked && prop.isItemClicked === true.toString()) {
      return 'flex';
    }
    return 'none';
  }};
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  animation: ${PopIn} 0.4s forwards;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.9;
  }
`;

/* eslint-disable indent */
import styled, { keyframes } from 'styled-components';
import { Overlay } from '../../common/style/index.style';
import { white, black } from '../../common/colors';

const IconSlideFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0em);
  }
`;
const IconSlideFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(2em);
  }
  100% {
    opacity: 1;
    transform: translateX(0em);
  }
`;
const GrowAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const SocialMediaContainer = styled(Overlay)`
  justify-content: space-between;
  padding: 0;
  flex-direction: column;
  position: relative;
`;

export const SocialMediaTitle = styled.h4`
  padding: 0.75em;
  align-items: start;
`;

export const SocialMediaIconContainer = styled.section<{
  inView?: string;
  index?: string;
}>`
  opacity: 0;
  animation: ${(prop) => {
    if (
      prop.inView
      && prop.inView === 'true'
      && prop.index
      && parseInt(prop.index, 10) <= 1
    ) {
      return IconSlideFromLeft;
    }
    if (prop.inView && prop.inView === 'true' && prop.index && parseInt(prop.index, 10) >= 3) {
      return IconSlideFromRight;
    }
    if (prop.inView && prop.inView === 'true') {
      return GrowAnimation;
    }
    return 'none';
  }}
    1s forwards;
`;

export const SocialMediaIconOverlay = styled.a<{
  color: string;
}>`
  color: ${white};
  display: flex;
  border-radius: 100%;
  background: ${black};
  margin: 0.5em 1em;
  transform: scale(1.3);
  transition: all 0.3s;
  &:hover {
    ${(prop) => `color: ${prop.color};`}
      transform: scale(1.8);
      background-color: ${white};
  }
`;

export const AnimatedIconBackground = styled.section`
  flexGrow: 1,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
`;

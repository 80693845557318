/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import GenericPage from '../Generic';
import { HEADER_AT, HEADER_IC, HEADER_MT } from '../../common/translation';
import {
  ADVISORY_TEAM,
  MANAGEMENT_TEAM,
  INTERNATIONAL_COORDINATION,
} from '../../common/routes';
import TeamIndividualCard from '../../Component/Teams';
import { TeamBodyContainer } from './index.style';

const pageData = {
  advisory_team: {
    title: HEADER_AT,
    individual: [
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/0A4DZmuGVgtY8q6N2l8YkiaUHtV7dZ-1.jpg',
        name: 'Dr Rishi Bikram Shah',
        position: 'Chief Advisory. Academician NAST, Secretary NNC IUCN',
        email: 'rishi@lotusholdings.com',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/NGZTua4jRG6k33jU18jeVggBY1K2F02e.jpg',
        name: 'Mr. Rajendra Khanal',
        position: 'Advisor, Program Coordinator IUCN Nepal',
        email: 'rajendra.khanal@iucn.org',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/3tnxlVbLuMqVjUPI3LKSnNNgGcxAF53u.jpg',
        name: 'Mr.Hom Raj Acharya',
        position: 'Advisor Country Representative Global Fairness Initiatives Nepal',
        email: 'hra000@gmail.com',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/TTqkKrJcPWWPlB8Vf_-1QeCnK-cFKQ4s.jpg',
        name: 'Ms.Anju Poudel',
        position: 'Advisor',
        email: 'luosang2002@gmail.com',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/MAiMaqrUxrgF5sgjkxmKCJYNbE0W3XnB.jpg',
        name: 'Dr. Raveena Desraj Shrestha',
        position: 'Advisor Banker/ CBO of MEGA Bank',
        email: 'raveenadesraj.shrestha@megabank.com',
      },
    ],
  },
  management_team: {
    title: HEADER_MT,
    individual: [
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/3tnxlVbLuMqVjUPI3LKSnNNgGcxAF53u.jpg',
        name: 'Mr.Hom Raj Acharya',
        position: 'CEO & Co-Founder',
        email: 'hra000@gmail.com',
      },
      // {
      //   image:
      //     'https://dflfmau07swcr.cloudfront.net/mc0uuIccNhQrGweCVkwdFyDEpRfc6Maf.jpg',
      //   name: 'Mr.Laxmi Gopal Shrestha',
      //   position: 'Chief Manager',
      //   email: 'lgs@ntc.net.np',
      // },
      // {
      //   image:
      //     'https://dflfmau07swcr.cloudfront.net/sc58XHj1017PAe88AfAaWQ8_ZuMMzu8R.jpg',
      //   name: 'Mr Kumar KC',
      //   position: 'Greenery Landsacpe Consultant',
      //   email: 'kumar1234@gmail.com',
      // },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/RGe9VPjUgrt1Yh1nazWj5GKN8jEy6wdK.jpg',
        name: 'Mr Yadav Bhandari',
        position: 'Co-Founder',
        email: 'savetheplanetnepal@gmail.com',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/nabin-thapa.jpg',
        name: 'Mr Yadav Bhandari',
        position: 'Co-Founder',
        email: 'savetheplanetnepal@gmail.com',
      },
      // {
      //   image:
      //     'https://dflfmau07swcr.cloudfront.net/7aPNUvz9zbJagvvWUHwAaJts8nEEh0gc.jpg',
      //   name: 'Mr.Ekraj Bhandari',
      //   position: 'Panchthar Coordinator',
      //   email: 'bhandari.ekraj@gmail.com',
      // },
      // {
      //   image:
      //     'https://dflfmau07swcr.cloudfront.net/wTme_Ni3N1ZxE8K7j4Rc-g41lhKBcElz.jpg',
      //   name: 'Dr Tirtha Bdr Shrestha',
      //   position: 'Chief, Scientist/Botanist',
      //   email: 'tirthabshrestha@gmail.com',
      // },
      // {
      //   image:
      //     'https://dflfmau07swcr.cloudfront.net/RGV5jDJzSbI3uQCGPkvRcmk316WYDZSM.jpg',
      //   name: 'Usha Neupane',
      //   position: 'Assistant Programme Coordinator',
      //   email: '',
      // },
    ],
  },
  international_coordination: {
    title: HEADER_IC,
    individual: [
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/SphgGe4qReybYdCKYvk9jkJ9hSTeIwpn.jpg',
        name: 'Anitaa Pathak',
        position: 'USA',
        email: '',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/9RN6WrqZP0mhxFWmZdCxL3nxaCYGKjxH.jpg',
        name: 'Sunil Gyawali',
        position: 'Australia',
        email: '',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/w6wxKetYWL-lA9dWw5S1HlLIcW_WA4CV.jpg',
        name: 'Damodar Badal',
        position: 'Japan',
        email: '',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/8kgIfXNt4z1MFLheY_23rmE1-DleIlIE.jpg',
        name: 'Prabhat Kiran Poudel',
        position: 'Denmark',
        email: '',
      },
      {
        image:
          'https://dflfmau07swcr.cloudfront.net/EBTds0ng_SkDYiyvSi-D9jubvLmU4jCw.jpg',
        name: 'Shyam Luitel',
        position: 'UK',
        email: '',
      },
    ],
  },
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Team(): JSX.Element {
  const query = useQuery();
  const type = query.get('type') || '';
  const [t] = useTranslation();

  let selectedData = {
    title: '',
    individual: [
      {
        image: '',
        name: '',
        position: '',
        email: '',
      },
    ],
  };

  switch (type) {
    case ADVISORY_TEAM:
      selectedData = pageData[ADVISORY_TEAM];
      break;
    case MANAGEMENT_TEAM:
      selectedData = pageData[MANAGEMENT_TEAM];
      break;
    case INTERNATIONAL_COORDINATION:
      selectedData = pageData[INTERNATIONAL_COORDINATION];
      break;
    default:
      selectedData = pageData[ADVISORY_TEAM];
      break;
  }

  const MappedIndividualCards = selectedData.individual.map(
    (elem) => <TeamIndividualCard
      image={elem.image}
      name={elem.name}
      position={elem.position}
      email={elem.email}
      key={uuid()}
    />,
  );

  const content = (
    <TeamBodyContainer>
      {MappedIndividualCards}
      </TeamBodyContainer>
  );
  return <GenericPage title={t(selectedData.title)} content={content} />;
}

export default Team;
